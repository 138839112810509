// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************
import React, { useEffect, useState } from "react"

import scbLogoWhite from '../img/SCB_Logo_White_RGB.png';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


// ************************************************************
// ************************************************************
// Pop-ups
// ************************************************************
// ************************************************************
const renderTooltipCertificate = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Get a certificate per email (free).
    </Tooltip>
);

const renderTooltipOffsetting = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        <strong>Offset </strong> your <strong>flight</strong>!
    </Tooltip>
);

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Projects you will support</Popover.Header>
        <Popover.Body>
            Do the right thing by <strong>offsetting</strong> your <strong>flight</strong>.
        </Popover.Body>
    </Popover>
);


/**************************************************************/
/**************************************************************/
/*************** Submit button*********************************/
/**************************************************************/
/**************************************************************/
function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}





function CalculatorFormFootballCP(props) {
    //const CalculatorForm = () => {
    
    var cabinClass = props.cabinClass
    var distanceInKm = props.distanceInKm
    var flightPrice = props.flightPrice
    //var carbonTons = props.carbonTonsPassedOn


    // JSON data retrieved from API
    const [calculation, setCalculation] = useState([])
    const [carbonDataJson, setCarbonDataJson] = useState([])
    const [vending, setVending] = useState([])

    

    // Retrieving values from parent
    //var distanceInKm = '10000'
    //var cabinClass = 'Economy'
    //var offsetting = 0
    //var flightPrice = 120

    //var email = ''


    // Declaring variables
    //var compensation = calculation['total_cost']
    const [carbonTons, setCarbonTons] = useState(props.carbonTonsPassedOn)
    const [compensation, setCompensation] = useState([])
    const [pricePerTon, setPricePerTon] = useState([])

    console.log('carbonTonsPassedOn')
    console.log(carbonTons)


    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [fullAddress, setFullAddress] = useState({
        // This variable can be accessed as follow: state.inputVcs
        firstName: "",
        lastName: "",
        email: "",
        address: "",
        country: ""
    })


    // Handling changes
    const handleFullAddress = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;
        setFullAddress({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...fullAddress,
            [name]: value
        })
    }



    /**************************************************************/
    /**************************************************************/
    /*************** API CALCULATE*********************************/
    /**************************************************************/
    /**************************************************************/
    var optionsApiCalculate = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CPF0ADD1A04B9D4F7EB80EA4F821148360:qsCHYFZ2sMS6MLOhzi9bgk2rtsQccXokQ5AVYFYkWZmQV2SaxRbWJ9pWpQwyGWvg',
            'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            //'client_id': 'CPB0309E64DDD14AAE8C77EB10F6CF7C4B',
            //'client_secret': 'ksDWvSy4N79j8OQ0s6tA1iTor2uDbWWl4oQXKS9bGrjaw2ZRW4YRe8sBNZ7cDatW',
            'calculator_id': 'SCBRawCarbon_DT34',
            'item_description': 'Offsetting a journey from your address to the stadium (' + distanceInKm + 'kms)',
            'carbon_tons': ''+carbonTons+''
            //'distance': distanceInKm,
            //'class': cabinClass,
        })
    }


    const fetchDataCalculate = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/calculate`, optionsApiCalculate)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                setCarbonDataJson(result.data)
                setCalculation(result.data.calculation)
                setCompensation(result.data.calculation.total_cost)
                setPricePerTon(result.data.calculation.price_per_ton)
                setCarbonTons(result.data.calculation.carbon_tons)
            })
        /*.then(data => {
            //setUsers(data)
            console.log(data)
        })*/
    }

    //console.log("optionsApiCalculate:")
    //console.log(optionsApiCalculate)
    console.log('calculation:')
    console.log(calculation)


    /**************************************************************/
    /**************************************************************/
    /*************** API VEND*********************************/
    /**************************************************************/
    /**************************************************************/
    var optionsApiVend = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CPF0ADD1A04B9D4F7EB80EA4F821148360:qsCHYFZ2sMS6MLOhzi9bgk2rtsQccXokQ5AVYFYkWZmQV2SaxRbWJ9pWpQwyGWvg',
            'Host': 'https://carboncompensate.starcb.com/api/v1/vend',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            calculation,
            'customer_reference': '',
            'consumer_email': fullAddress.email,
            'on_behalf_of_name': fullAddress.firstName + ' ' + fullAddress.lastName

            /*'calculation': {'endpoint_id': 'b0309e64-ddd1-4aae-8c77-eb10f6cf7c4b',
            'name': 'CP Flight Calculator',
            'calculator_id': '8e8fb663-176c-4e36-a212-bcb62694766c',
            'short_description': 'Flight Offset',
            'long_description': 'A 16,565km flight in Economy class.',
            'currency': 'USD',
            'carbon_tons': '1.6188',
            'price_per_ton': '12.55',
            'total_cost': '20.32',
            'trees_planted': 0,
            'calculation_parameters': {'calculator_id': 'CPFlightCalculator_237D',
             'distance': '16565',
             'class': 'Economy'},
            'signature': 'e1246e46e4b9c4daa856ec411193a0fa187fddc27a360d552659e22f0771dc5f',
            'id': '2006b535-da5c-4d64-adc4-1e3e9aa121fa',
            'created_at': '2023-03-14T11:15:34.000000Z'},
           'customer_reference': 'Customer-seb',
           'consumer_email': 'sebastien.roh@starcb.com',
           'on_behalf_of_name': 'Seb Roh'*/
        })
    }


    //console.log("optionsApiVend:")
    //console.log(optionsApiVend)

    const fetchDataVend = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/vend`, optionsApiVend)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                setVending(result.data)

            })
        /*.then(data => {
            //setUsers(data)
            console.log(data)
        })*/
    }



    /*useEffect(() => {
        fetchDataCalculate()

        if (isLoading) {
            
            fetchDataVend();

            simulateNetworkRequest().then(() => {
                setLoading(false);
            });


        }

    }, [])*/





    // Toggle button /// compensating flight emission
    //const [checkedOffsetting, setCheckedOffsetting] = useState(false);
    //const [checkedCertificate, setCheckedCertificate] = useState(false);



    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [state, setStateOffsetting] = useState({
        // This variable can be accessed as follow: state.inputVcs
        offsetting: "false"
    })

    // Handling changes
    const handleChangeOffsetting = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value =
            evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setStateOffsetting({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});


        props['compensation'](compensation);
        props['pricePerTon'](pricePerTon);
        props['carbonTons'](carbonTons);
        props['flightPrice'](flightPrice);


        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);

    }


    const [isSubmittedStatus, setSubmittedOrderStatus] = useState(-1) // -1 = initial state (no message to be displayed)


    /**************************************************************/
    /**************************************************************/
    /*************** Submit button*********************************/
    /**************************************************************/
    /**************************************************************/
    // Loading button
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {

        fetchDataCalculate()

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            fetchDataVend();
        }

    }, [isLoading]);



    //const handleClick = () => setLoading(true);


    const handleClick2 = () => {
        setLoading(true);

        simulateNetworkRequest().then(() => {

            if (fullAddress.email.includes('@') & fullAddress.firstName.length > 0 & fullAddress.lastName.length > 0) {
                setSubmittedOrderStatus(1)
                setFullAddress({['firstName']: ''})
                setFullAddress({['lastName']: ''})
                setFullAddress({['email']: ''})

            } else {
                setSubmittedOrderStatus(0)
            }


        });

    }

    return (
        <>
            <div className="text-center">
                <h6>
                    <span className="">Billing address</span>
                </h6>
            </div>

            <Form className="form mb-4">
                <fieldset>

                    {/*<div class="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">Distance (in kms)</Form.Label>
                            <Form.Control id="disabledTextInput" placeholder="Number of kms" />
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledSelect">Class</Form.Label>
                        <Form.Select id="disabledSelect">
                            <option>Disabled select</option>
                            <option>Economy</option>
                            <option>Business</option>

                        </Form.Select>
    </Form.Group>*/}


                    <div class="row">

                        <div class="col-sm-6">
                            <label for="firstName" class="form-label">First name</label>
                            <input type="text" class="form-control" id="firstName" placeholder="" required="" name="firstName" value={fullAddress.firstName} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Valid first name is required.
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <label for="lastName" class="form-label">Last name</label>
                            <input type="text" class="form-control" id="lastName" placeholder="" required="" name="lastName" value={fullAddress.lastName} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Valid last name is required.
                            </div>
                        </div>

                        <div class="col-12">
                            <label for="email" class="form-label">Email {/*<span class="text-muted">(Optional)</span>*/}</label>
                            <input type="email" class="form-control" id="email" placeholder="" required="" name="email" value={fullAddress.email} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Please enter a valid email address.
                            </div>
                        </div>

                        <div class="col-8">
                            <label for="address" class="form-label">Address</label>
                            <input type="text" class="form-control" id="address" placeholder="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;" required="" name="address" value={fullAddress.address} onChange={handleFullAddress} disabled="disabled" />
                            <div class="invalid-feedback">
                                Please enter your address.
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="country" class="form-label">Country</label>
                            <select class="form-select" id="country" required="" name="country" value={fullAddress.country} onChange={handleFullAddress} disabled="disabled">
                                <option value="other" selected>&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;</option>
                                <option>Choose...</option>
                                <option>Australia</option>
                                <option>Canada</option>
                                <option>Switzerland</option>
                                <option>United Kingdom</option>
                                <option>USA</option>
                            </select>
                            <div class="invalid-feedback">
                                Please select a valid country.
                            </div>
                            <br />
                        </div>



                        {/*<div class="col-md-6">
                            <label for="distance" class="form-label">Distance (in kms)</label>
                            <input type="number" class="form-control" id="distance" placeholder="" required="" />
                            <div class="invalid-feedback">
                                Number of kms.
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="class" class="form-label">Class</label>
                            <select class="form-select" id="class" required="">
                                <option value="">Choose...</option>
                                <option>Economy</option>
                                <option>Business</option>
                            </select>
                            <div class="invalid-feedback">
                                Please select a class.
                            </div>
</div>*/}


                        {/*<div class="col-md-4">
                            <label for="zip" class="form-label">Zip</label>
                            <input type="text" class="form-control" id="zip" placeholder="" required="" />
                            <div class="invalid-feedback">
                                Zip code required.
                            </div>
</div>*/}


                        {/*<hr class="my-2" />*/}


                        <div class="row">

                            <div class="col-lg-6">

                                {/*<OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipOffsetting}
                                >
                                    <ToggleButton
                                        className="mb-2"
                                        id="toggle-check1"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={checkedOffsetting}
                                        value="1"
                                        onChange={(e) => checkedOffsetting(e.currentTarget.checkedOffsetting)}
                                    >
                                        Compensate flight Emissions&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-airplane-fill" viewBox="0 0 16 16">
                                            <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Z" />
                                        </svg>

                                    </ToggleButton>
                                </OverlayTrigger>*/}

                                <Form.Check
                                    type="switch"
                                    id="custom-switch-offsetting"
                                    label="Carbon compensate your order"
                                    name="offsetting"
                                    defaultChecked={state.offsetting ? "checked" : "unchecked"}
                                    value={state.offsetting}
                                    onChange={handleChangeOffsetting}
                                />

                            </div>
                            <div class="col-lg-6">
                                {/*<OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipCertificate}
                                >
                                    <ToggleButton
                                        className="mb-2"
                                        id="toggle-check2"
                                        type="checkbox"
                                        variant="outline-secondary"
                                        checked={checkedCertificate}
                                        value="1"
                                        onChange={(e) => setCheckedCertificate(e.currentTarget.checkedCertificate)}
                                    >
                                        Certificate&nbsp;&nbsp;<i class="bi bi-file-pdf-fill"></i>

                                    </ToggleButton>
                                </OverlayTrigger>*/}


                                <Form.Check
                                    type="switch"
                                    id="custom-switch-certificate"
                                    label="Email carbon neutral certificate"
                                    defaultChecked="checked"
                                    disabled="disabled"
                                />

                                <br />
                            </div>

                        </div>


                        {/*<hr class="my-2" />*/}


                        {/*<OverlayTrigger trigger="hover" placement="right" overlay={popover}>*/}
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className="accordingTitle">Pay by card&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-credit-card-2-back-fill" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5H0V4zm11.5 1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-2zM0 11v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1H0z" />
                                </svg>
                                </Accordion.Header>
                                <Accordion.Body className="accordionBody">


                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="cc-name" class="form-label">Name on card</label>
                                            <input type="text" class="form-control" id="cc-name" placeholder="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;" required="" disabled="disabled" />
                                            <div class="invalid-feedback">
                                                Name on card is required
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="cc-number" class="form-label">Credit card</label>
                                            <input type="text" class="form-control" id="cc-number" placeholder="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;" required="" disabled="disabled" />
                                            <div class="invalid-feedback">
                                                Credit card number is required
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="cc-expiration" class="form-label">Expiration</label>
                                            <input type="text" class="form-control" id="cc-expiration" placeholder="&#x2022;&#x2022;/&#x2022;&#x2022;&#x2022;&#x2022;" required="" disabled="disabled" />
                                            <div class="invalid-feedback">
                                                Expiration date required
                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <label for="cc-cvv" class="form-label">CVV</label>
                                            <input type="text" class="form-control" id="cc-cvv" placeholder="&#x2022;&#x2022;&#x2022;" required="" disabled="disabled" />
                                            <div class="invalid-feedback">
                                                Security code required
                                            </div>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            <br />
                        </Accordion>



                        <div className="text-center">
                            {/*<h4>
                                    <Badge bg="dark" text="light" as="Button" class="w-100 btn btn-primary btn-lg" type="submit">Continue to checkout</Badge>
                            </h4>*/}
                            {/*<Button class="btnSubmit"
                                disabled={isLoading}
                                onClick={!isLoading ? handleClick : null}
                            >
                                {isLoading ? 'Processing payment…' : checkoutButtonText}
                        </Button>*/}
                            <input class="btnSubmit" type="button" onClick={!isLoading ? handleClick2 : null} value={isLoading ? '  Processing…  ' : '  Submit Order  '} disabled={isSubmittedStatus == 1 ? 'disabled' : ''} />
                        </div>

                    </div>

                </fieldset>
            </Form>


            {isSubmittedStatus == 0 ?
                <Alert key='danger' variant='danger'>
                    Please provide your <b>first name</b>, <b>last name</b> and <b>email</b> address before submitting the order.
                </Alert>
                :
                null
            }

            {isSubmittedStatus == 1 ?
                <Alert key='success' variant='success'>
                    Thank you for your order.
                    <br /><br />You will receive your carbon neutral certificate per email in a few minutes.
                </Alert>
                :
                null
            }

        </>
    )
}

export default CalculatorFormFootballCP