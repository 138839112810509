// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************
import React, { useState } from "react"

import vcs0535 from '../marketing_material/PDF frontpage/VCS0535.png'
import gs447 from '../marketing_material/PDF frontpage/GS447.png'
import vcs674 from '../marketing_material/PDF frontpage/VCS674.png'
import gs5047 from '../marketing_material/PDF frontpage/GS5047.png'
import gs1134 from '../marketing_material/PDF frontpage/GS1134.png'


import gs447_picture from '../marketing_material/gs447_picture.png'
import gs447_picture2 from '../marketing_material/gs447_picture_2.png'

import vcs674_picture from '../marketing_material/vcs674_picture.png'
import vcs674_picture2 from '../marketing_material/vcs674_picture2.png'
import vcs674_picture3 from '../marketing_material/vcs674_picture3.png'

import vcs674_picture5 from '../marketing_material/vcs674_rimba-raya-river-web-scaled_2.jpg'
import vcs674_picture6 from '../marketing_material/vcs674_rimba-raya-mom-baby-climbing-web-1024x681_2.jpg'



import gs5047_picture from '../marketing_material/gs5047_picture.png'
import gs5047_picture2 from '../marketing_material/gs5047_picture2.png'
import gs5047_picture3 from '../marketing_material/gs5047_picture3.png'

import gs1134_picture from '../marketing_material/gs1134_picture.png'

import vcs1950_picture from '../marketing_material/original_vcs_1950_RubyJean-Mulilo-057_1_3.jpg'
import vcs1950_picture2 from '../marketing_material/original_vcs_1950_RubyJean-Mulilo-001_3.jpg'
import vcs1950_picture3 from '../marketing_material/original_vcs_1950_Longyuan_3.jpg'


import vcs1329_picture1 from '../marketing_material/VCS1329/vcs1329_1.jpg'
import vcs1329_picture2 from '../marketing_material/VCS1329/vcs1329_2.jpg'
import vcs1329_picture3 from '../marketing_material/VCS1329/vcs1329_3.jpg'

import vcs981_picture1 from '../marketing_material/VCS981/vcs981_1.jpg'
import vcs981_picture2 from '../marketing_material/VCS981/vcs981_2.jpg'

import vcs2252_picture1 from '../marketing_material/VCS2252/vcs2252_1.jpg'

import gs7599_picture1 from '../marketing_material/GS7599/gs7599_1.jpg'
import gs7599_picture2 from '../marketing_material/GS7599/gs7599_2.jpg'

import gs7132_picture1 from '../marketing_material/GS7132/gs7132_1.jpg'
import gs7132_picture2 from '../marketing_material/GS7132/gs7132_2.jpg'




import scbLogoWhite from '../img/SCB_Logo_White_RGB.png';

import sdg1 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-01.png'
import sdg2 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-02.png'
import sdg3 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-03.png'
import sdg4 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-04.png'
import sdg5 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-05.png'
import sdg6 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-06.png'
import sdg7 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-07.png'
import sdg8 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-08.png'
import sdg9 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-09.png'
import sdg10 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-10.png'
import sdg11 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-11.png'
import sdg12 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-12.png'
import sdg13 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-13.png'
import sdg14 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-14.png'
import sdg15 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-15.png'
import sdg16 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-16.png'
import sdg17 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-17.png'

import mzFlag from '../img/country-flags-main/png100px/mz.png'
import inFlag from '../img/country-flags-main/png100px/in.png'
import brFlag from '../img/country-flags-main/png100px/br.png'
import idFlag from '../img/country-flags-main/png100px/id.png'
import trFlag from '../img/country-flags-main/png100px/tr.png'
import ugFlag from '../img/country-flags-main/png100px/ug.png'
import zaFlag from '../img/country-flags-main/png100px/za.png'

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';


const VcmProjectCarouselIT = () => {
    return (
        <>

            <div className="text-center">
                <h6>
                    <span className="">Emissions reduction projects we support</span>
                </h6>
            </div>


            <div class="row">

                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 text-center">
                    <Card>
                        <Carousel slide={true} variant="light">
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={vcs674_picture5} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={vcs674_picture6} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <br />
                        <h6 class="card-title">Biodiversity Reserve Project</h6>
                        <p class="card-text"><small>Preserving Indonesia's peat swamp forests. </small>
                            <a href="https://info.starcb.com/view/549931655/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                    <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg>
                            </a>
                            <br />
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg1} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg2} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg3} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg4} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg5} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg6} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg7} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg8} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg9} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg10} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg11} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg12} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg13} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg14} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg15} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg16} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg17} />&nbsp;


                        </p>
                        {/*<p class="card-text"><small>Indonesia <img src="https://www.fivb.org/Vis2009/Images/Flags/Small/id.png" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nature-based<br /></small>
                        </p>*/}

                        <div style={{
                            width: '100%', height: '10%',
                            position: 'absolute',
                            top: '0%',
                            left: '0%'
                        }}>
                            <span class="badge badgeProjectCountry">Indonesia <img  height="10em" src={idFlag} /></span>
                            &nbsp;&nbsp;
                            <span class="badge badgeProjectCountry">Nature-based</span>
                        </div>
                    </Card>
                    <br />
                </div>




                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 text-center">
                    <Card>
                        <Carousel slide={true} variant="light">
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={vcs1950_picture} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={vcs1950_picture2} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <br />
                        <h6 class="card-title">Longyuan Mulilo North Wind Facility</h6>
                        <p class="card-text"><small>Powering wind to the South African grid. </small>
                            <a href="https://info.starcb.com/view/880613720/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                    <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg>
                            </a>
                            <br />
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg3} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg4} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg6} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg13} />

                        </p>
                        {/*<p class="card-text"><small>Turkey <img src="https://www.fivb.org/Vis2009/Images/Flags/Small/tr.png" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Renewables<br /></small>
                        </p>*/}

                        <div style={{
                            width: '100%', height: '10%',
                            position: 'absolute',
                            top: '0%',
                            left: '0%'
                        }}>
                            <span class="badge badgeProjectCountry">South Africa <img height="10em" src={zaFlag} /></span>
                            &nbsp;&nbsp;
                            <span class="badge badgeProjectCountry">Renewables</span>
                        </div>
                    </Card>
                    <br />
                </div>



                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 text-center">
                    <Card>
                        <Carousel slide={true} variant="light">
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={gs7132_picture1} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={gs7132_picture2} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <br />
                        <h6 class="card-title">Mozambique Safe Water Project</h6>
                        <p class="card-text"><small>Providing safe water to households within Manica province. </small>
                            <a href="https://info.starcb.com/view/125218946/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                    <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg>
                            </a>
                            <br />
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg3} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg5} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg6} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg13} />

                            {/*<img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg1} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg2} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg3} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg4} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg5} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg6} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg7} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg8} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg9} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg10} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg11} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg12} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg13} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg14} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg15} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg16} />&nbsp;
                    <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg17} />&nbsp;*/}


                        </p>
                        {/*<p class="card-text"><small>Indonesia <img src="https://www.fivb.org/Vis2009/Images/Flags/Small/id.png" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nature-based<br /></small>
                        </p>*/}

                        <div style={{
                            width: '100%', height: '10%',
                            position: 'absolute',
                            top: '0%',
                            left: '0%'
                        }}>
                            <span class="badge badgeProjectCountry">Mozambique <img  height="10em" src={mzFlag} /></span>
                            &nbsp;&nbsp;
                            <span class="badge badgeProjectCountry">Community-based</span>
                        </div>
                    </Card>
                    <br />
                </div>




                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-12 text-center">
                    <Card>
                        <Carousel slide={true} variant="light">
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={gs7599_picture1} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Card.Img variant="top" width="100%" src={gs7599_picture2} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <h4></h4>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                        <br />
                        <h6 class="card-title">Windkraftprojekt in Karnataka von OMWPL</h6>
                        <p class="card-text"><small>Bereitstellung von erneuerbarer Energie für das indische Stromnetz. </small>
                            <a href="https://info.starcb.com/view/500728484/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                    <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                    <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                </svg>
                            </a>
                            <br />
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg7} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg8} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg13} />

                            {/*<img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg1} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg2} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg3} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg4} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg5} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg6} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg7} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg8} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg9} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg10} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg11} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg12} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg13} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg14} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg15} />&nbsp;
                            <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg16} />&nbsp;
                    <img style={{marginRight: "1px", marginTop: "5px"}} width='20em' src={sdg17} />&nbsp;*/}


                        </p>
                        {/*<p class="card-text"><small>Indonesia <img src="https://www.fivb.org/Vis2009/Images/Flags/Small/id.png" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nature-based<br /></small>
                        </p>*/}

                        <div style={{
                            width: '100%', height: '10%',
                            position: 'absolute',
                            top: '0%',
                            left: '0%'
                        }}>
                            <span class="badge badgeProjectCountry">India <img  height="10em" src={inFlag} /></span>
                            &nbsp;&nbsp;
                            <span class="badge badgeProjectCountry">Renewables</span>
                        </div>
                    </Card>
                    <br />
                </div>




            </div>




            {/*

                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <Card style={{ width: '100%' }}>
                                <Card.Img variant="top" src={gs5047} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                                <div class="">
                                    <a href="https://info.starcb.com/view/880069420/" target="_blank">Project insights <i className="bi bi-link-45deg"></i></a>
                                </div>
                            </Card>
                        </div>

                        <div class="col-md-6">
                            <Card style={{ width: '100%' }}>

                                <Card.Img variant="top" src={gs447} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                                <div class="">
                                    <a href="https://info.starcb.com/view/740034714/" target="_blank">Project insights <i className="bi bi-link-45deg"></i></a>
                                </div>
                            </Card>
                        </div>

                        <div class="col-md-6">
                            <Card style={{ width: '100%' }}>

                                <Card.Img variant="top" src={vcs674} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                                <div class="">
                                    <a href="https://info.starcb.com/view/549931655/" target="_blank">Project insights <i className="bi bi-link-45deg"></i></a>
                                </div>
                            </Card>
                        </div>

                        <div class="col-md-6">
                            <Card style={{ width: '100%' }}>

                                <Card.Img variant="top" src={gs1134} />
                                <Carousel.Caption>
                                    <h3></h3>
                                    <p></p>
                                </Carousel.Caption>
                                <div class="">
                                    <a href="https://info.starcb.com/view/387464743/" target="_blank">Project insights <i className="bi bi-link-45deg"></i></a>
                                </div>
                            </Card>
                        </div>

                    </div>

    */}






        </>
    )
}

export default VcmProjectCarouselIT