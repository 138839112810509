// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************
import React, { useEffect, useState } from "react"

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// ************************************************************
// ************************************************************
// SVG icons
// ************************************************************
// ************************************************************
let seaTankerIcon = [];
seaTankerIcon.push(
    <svg viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" height="40" fill="var(--main-navbar-background)">
        <path d="M844.627,562.68l1.84-18.41c0.37-3.72-2.52-6.97-6.26-7.05l-20.3-0.4v-13.04  c0-2.709-0.24-5.368-0.7-7.946h2.704c3.314,0,6-2.687,6-6v-4.667c0-3.314-2.686-6-6-6h-402c-3.314,0-6,2.686-6,6v4.667  c0,3.313,2.686,6,6,6h2.695c-0.46,2.579-0.7,5.237-0.7,7.946v5.16l-10.82-0.21c-1.6-0.03-3.13-0.65-4.28-1.75l-8.57-8.12  l-30.75-29.12l-13.52-12.81c-1.19-1.13-2.77-1.76-4.41-1.76h-53.24h-136.4c-4.2,0-7.27,3.97-6.2,8.03l5.53,21.15  c0.22-0.02,0.44-0.03,0.67-0.03h136.89h52.75c1.64,0,3.22,0.63,4.41,1.76l3.96,3.75l40.01,37.9l8.87,8.4  c1.15,1.09,2.68,1.72,4.28,1.75l10.82,0.21l89.99,1.78l12.69,0.26l89.88,1.77l12.94,0.26l88.92,1.76l14.7,0.29l85.76,1.7l23.42,0.46  h0.04C842.476,566.43,844.406,564.89,844.627,562.68z M524.586,523.78v7.2l-12.69-0.26v-6.94c0-2.709-0.24-5.368-0.699-7.946h14.089  C524.826,518.412,524.586,521.071,524.586,523.78z M627.256,523.78v9.23l-12.69-0.25v-8.98c0-2.709-0.24-5.368-0.7-7.946h14.089  C627.496,518.412,627.256,521.071,627.256,523.78z M729.926,523.78v11.26l-12.69-0.25v-11.01c0-2.709-0.24-5.368-0.699-7.946h14.089  C730.166,518.412,729.926,521.071,729.926,523.78z"></path>
        <path d="M395.106,568.47l-15.45-14.64l-34.32-32.51h-79.77h-10.66h-23.34h-10.66h-23.34h-34.66l19.97,92.39  c0.74,3.41,3.29,5.79,6.2,5.79h646.08c3.3,0,6.06-3.02,6.38-6.99l1.436-17.47c0.316-3.838,0.474-7.688,0.474-11.54l-432.7-8.62  C404.916,574.77,399.356,572.49,395.106,568.47z"></path>
        <path d="M214.906,441.5h-11.34c-3.31,0-6,3.05-6,6.83v9.84h23.34v-9.84  C220.906,444.55,218.216,441.5,214.906,441.5z"></path>
        <path d="M249.736,380.5h-13c-2.85,0-5.17,2.31-5.17,5.17v72.5h23.34v-72.5  C254.906,382.81,252.586,380.5,249.736,380.5z"></path>
        <path d="M359.156,380.5h-54.5c-4.61,0-8.34,3.73-8.34,8.33v35.67h-22.41c-4.61,0-8.34,3.73-8.34,8.33v25.34  h30.75h53.24c6.03,0,11.75,2.29,16.12,6.43l1.81,1.72l11.69,11.08l19.06,18.05v-62.62c0-4.6-3.73-8.33-8.33-8.33h-22.42v-35.67  C367.486,384.23,363.756,380.5,359.156,380.5z"></path>
        <path d="M440.806,487.12h17.77h16.66h17.77c-5.21-3.72-11.25-6.35-17.77-7.57v-21.383h86.01v21.383  c-6.53,1.23-12.56,3.86-17.77,7.57h17.77h16.66h17.77c-5.21-3.71-11.24-6.34-17.77-7.57v-21.383h86.01v21.383  c-6.52,1.22-12.56,3.85-17.77,7.57h17.77h16.66h17.77c-5.21-3.72-11.25-6.35-17.77-7.57v-21.383h86.01v21.383  c-6.53,1.23-12.56,3.86-17.77,7.57h17.77h16.66h17.77c-5.21-3.71-11.24-6.34-17.77-7.57v-26.469c0-0.014,0.004-0.026,0.004-0.041  v-6.414c0-1.416-0.574-2.698-1.503-3.626c-0.926-0.928-2.206-1.5-3.621-1.5h-0.002h-6.418h-96.25h-6.42h-96.25h-6.42h-96.25h-3.211  h-3.209c-2.83,0-5.12,2.29-5.12,5.13v3.108c0,0.032-0.005,0.064-0.005,0.096s0.005,0.063,0.005,0.095v29.621  C452.046,480.77,446.016,483.4,440.806,487.12z"></path>
    </svg>
);

// ************************************************************
// ************************************************************
// Pop-ups
// ************************************************************
// ************************************************************
const renderTooltipCo2Emission = (
    <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
        Emissions in tonnes
    </Tooltip>
);

const renderTooltipShipmentViaSeaTanker = (
    <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
        Shipment via sea tanker
    </Tooltip>
);

const renderTooltipShipmentViaCargoShip = (
    <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
        Shipment via cargo ship
    </Tooltip>
);

const renderTooltipCertificate = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Get a certificate per email (free).
    </Tooltip>
);

const renderTooltipOffsetting = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        <strong>Offset </strong> your <strong>flight</strong>!
    </Tooltip>
);

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Projects you will support</Popover.Header>
        <Popover.Body>
            Do the right thing by <strong>offsetting</strong> your <strong>flight</strong>.
        </Popover.Body>
    </Popover>
);


// ************************************************************
// ************************************************************
// Number formatting: thousand separator and 2 decimals
// ************************************************************
// ************************************************************
const numberFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
});


/**************************************************************/
/**************************************************************/
/*************** Simulate Network Request / timeout ***********/
/**************************************************************/
/**************************************************************/
function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}



function CalculatorFormOffsettingToolFreight(props) {
    // JSON data retrieved from API
    var [calculation, setCalculation] = useState([])
    const [carbonDataJson, setCarbonDataJson] = useState([])
    const [vending, setVending] = useState([])
    const [compensation, setCompensation] = useState([])
    const [pricePerTon, setPricePerTon] = useState(props.pricePerTon)

    //const [sumCo2, setSumCo2] = useState(0)



    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [fullAddress, setFullAddress] = useState({
        // This variable can be accessed as follow: state.inputVcs
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        address: "",
        country: ""
    })

    // Handling changes
    const handleFullAddress = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;
        setFullAddress({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...fullAddress,
            [name]: value
        })
    }

    // ************************************************************
    // ************************************************************
    // Pop-ups
    // ************************************************************
    // ************************************************************
    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            CO2 compensation (USD {pricePerTon} per ton).
        </Tooltip>
    );


    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [state, setStateOffsetting] = useState({
        // This variable can be accessed as follow: state.inputVcs
        offsetting: true,
    })

    // Handling changes
    const handleChangeOffsetting = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value =
            evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setStateOffsetting({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);
    }


    /**************************************************************/
    /**************************************************************/
    /*************** Triggered when Submit button*********************************/
    /**************************************************************/
    /**************************************************************/
    const [isSubmittedStatus, setSubmittedOrderStatus] = useState(-1) // -1 = initial state (no message to be displayed)

    // Loading button
    const [isLoading, setLoading] = useState(false);


    const handleClickOnCompensate = () => {
        setLoading(true);

        simulateNetworkRequest().then(() => {

            if (fullAddress.email.includes('@') & fullAddress.company.length > 0) {
                setSubmittedOrderStatus(1)
                setFullAddress({ ['company']: '' })
                setFullAddress({ ['email']: '' })

                // If Compact vehicle select, then proceed
                /*if (stateFleetType.compactOffered) {
                    fetchDataCalculate()
                    fetchDataVend()
                }*/

            } else {
                setSubmittedOrderStatus(0)
            }
        });
    }



    /**************************************************************/
    /**************************************************************/
    /* Sea Tankers module
    /**************************************************************/
    /**************************************************************/
    const [seaTankersArray, setSeaTankersArray] = useState([{ key: Date.now(), seaTankerType: "New type", seaTankerSize: "0.00294", seaTankerLoad: "200", seaTankerDistance: "7000" }])

    // Function to add an object to the array
    const addSeaTankers = () => {
        const newObject = { key: Date.now(), seaTankerType: "New type", seaTankerSize: "0.00294", seaTankerLoad: "200", seaTankerDistance: "7000" };
        setSeaTankersArray([...seaTankersArray, newObject]);


        props.sendSeaTankersArrayToParent(seaTankersArray);

    };

    // Function to remove an object from the array by key
    const removeSeaTankers = (key) => {
        const updatedArray = seaTankersArray.filter((obj) => obj.key !== key);
        setSeaTankersArray(updatedArray);

        props.sendSeaTankersArrayToParent(updatedArray);

    };

    // Function to update the name of an object by key
    const updateSeaTankers = (key, newName, newValue) => {
        // Retrive the target id
        //const id = evt.target.id;
        // Retrive the target name
        //const name = newName.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        //const newValue = evt.target.value;

        const updatedArray = seaTankersArray.map((obj) =>
            // [newName] --> to specify the property name to update
            obj.key === key ? { ...obj, [newName]: newValue } : obj
        );
        setSeaTankersArray(updatedArray);

        props.sendSeaTankersArrayToParent(updatedArray);
    }

    console.log("SeaTankers array of objects:")
    console.log(seaTankersArray)



    /**************************************************************/
    /**************************************************************/
    /* Cargo ships module
    /**************************************************************/
    /**************************************************************/
    const [cargoShipsArray, setCargoShipsArray] = useState([{ key: Date.now(), cargoShipType: "New type", cargoShipSize: "0.002535", cargoShipLoad: "100", cargoShipDistance: "3000" }])

    // Function to add an object to the array
    const addCargoShips = () => {
        const newObject = { key: Date.now(), cargoShipType: "New type", cargoShipSize: "0.002535", cargoShipLoad: "100", cargoShipDistance: "3000" };
        setCargoShipsArray([...cargoShipsArray, newObject]);

        props.sendCargoShipsArrayToParent(cargoShipsArray);

    };

    // Function to remove an object from the array by key
    const removeCargoShips = (key) => {
        const updatedArray = cargoShipsArray.filter((obj) => obj.key !== key);
        setCargoShipsArray(updatedArray);

        props.sendCargoShipsArrayToParent(updatedArray);

    };

    // Function to update the name of an object by key
    const updateCargoShips = (key, newName, newValue) => {
        // Retrive the target id
        //const id = evt.target.id;
        // Retrive the target name
        //const name = newName.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        //const newValue = evt.target.value;

        const updatedArray = cargoShipsArray.map((obj) =>
            // [newName] --> to specify the property name to update
            obj.key === key ? { ...obj, [newName]: newValue } : obj
        );
        setCargoShipsArray(updatedArray);

        props.sendCargoShipsArrayToParent(updatedArray);

    }

    console.log("CargoShips array of objects:")
    console.log(cargoShipsArray)


    /**************************************************************/
    /**************************************************************/
    /* Calculate CO2 emissions and compensations
    /**************************************************************/
    /**************************************************************/

    const calculateSumCo2 = () => {
        //const { items } = this.state;
        // Using the reduce function to calculate the sum
        const sumCargoShips = cargoShipsArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.cargoShipSize) * parseFloat(currentItem.cargoShipLoad) * parseFloat(currentItem.cargoShipDistance) / 1000.0), 0);
        const sumSeaTankers = seaTankersArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.seaTankerSize) * parseFloat(currentItem.seaTankerLoad) * parseFloat(currentItem.seaTankerDistance) / 1000.0), 0);


        props.sendSumCo2(sumCargoShips + sumSeaTankers);


        //const sumSeaTankers = seaTankersArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.SeaTankerSize)*parseFloat(currentItem.SeaTankerLoad)*parseFloat(currentItem.SeaTankerDistance)/1000.0), 0);
        return sumCargoShips + sumSeaTankers;
    };



    // componentDidUpdate
    useEffect(() => {
        const sumCo2 = calculateSumCo2();
        //setSumCo2(calculateSumCo2())

        //console.log('Sum of cargo ships CO2:', sumCo2);
    }, [cargoShipsArray][seaTankersArray]); // Specify the dependency (items) to trigger the effect when it changes







    /**************************************************************/
    /**************************************************************/
    /*************** API CALCULATE METHOD *************************/
    /**************************************************************/
    /**************************************************************/

    // Default optionsApiCalculate (will be replaced when clicked on Compensate to get the updated carbon_tons)
    {/*const [optionsApiCalculate, setOptionsApiCalculate] = useState(
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
                'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
                'Access-Control-Allow-Origin': '*',
                'mode': 'no-cors'
            },
            body: JSON.stringify({
                'calculator_id': 'SCBRawCarbon_DT34',
                'item_description': 'Bulk offsetting of freighting goods via the offsetting tool',
                'carbon_tons': 100
                //'quantity': 1
            })
        }
    )*/}


    var optionsApiCalculate = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
            'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            'calculator_id': 'SCBRawCarbon_DT34',
            'item_description': 'Bulk offsetting of freighting goods via the offsetting tool',
            'carbon_tons': 1
            //'quantity': 1
        })
    }




    useEffect(() => {

        fetchDataCalculate()

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            fetchDataVend();
        }

    }, [isLoading]);




    // Proceed each time isLoading = true or is amended
    {/*useEffect(() => {
        console.log("useEffect called. ")        

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            // Update the setOptionsApiCalculate with the latest carbon_tons (replacing the initial data)
            setOptionsApiCalculate({
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
                    'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
                    'Access-Control-Allow-Origin': '*',
                    'mode': 'no-cors'
                },
                body: JSON.stringify({
                    'calculator_id': 'SCBRawCarbon_DT34',
                    'item_description': 'Bulk offsetting of freighting goods via the offsetting tool',
                    'carbon_tons': 100
                })
            })            

            fetchDataCalculate()
            console.log("my order.fleetSizeCompact just before VENDING method:")

            fetchDataVend()
        }

    }, [isLoading]);*/}




    const fetchDataCalculate = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/calculate`, optionsApiCalculate)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                //setCarbonDataJson(result.data)
                setCalculation(result.data.calculation)
                //setCompensation(result.data.calculation.total_cost)
                //setPricePerTon(result.data.calculation.price_per_ton)
            })
    }


    /**************************************************************/
    /**************************************************************/
    /*************** API VEND METHOD ******************************/
    /**************************************************************/
    /**************************************************************/
    var optionsApiVend = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
            'Host': 'https://carboncompensate.starcb.com/api/v1/vend',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            calculation,
            'customer_reference': '',
            'consumer_email': fullAddress.email,
            'on_behalf_of_name': fullAddress.company
        })
    }

    const fetchDataVend = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/vend`, optionsApiVend)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                setVending(result.data)
            })
    }



    return (
        <>

            {/* SEA TANKERS*/}
            {/*<div className="text-left">
                <h6>
                    <span className=""><Badge pill bg="dark">{seaTankersArray.length}</Badge>&nbsp;&nbsp;Shipment(s) by sea tankers</span>
                </h6>
            </div>*/}
            <br />
            <Form className="form mb-4">
                <fieldset>
                    <Button variant="dark" size="sm" href="#" onClick={addSeaTankers}>Add tanker shipment&nbsp;&nbsp;<i class="bi bi-droplet"></i></Button>

                    <Row>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Type
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Size
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Load &#183; tonnes
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Distance &#183; kms
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                            CO2 <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                            $ <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                            <i class="bi bi-trash3"></i>
                        </Col>
                    </Row>

                    {
                        seaTankersArray.map((obj) => (

                            <Row>
                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipShipmentViaSeaTanker}><i class="bi bi-droplet" style={{ "color": 'var(--main-font-color)' }}></i>
                                    </OverlayTrigger>
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Select key={obj.key} name="seaTankerType" value="1" onChange={(e) => updateSeaTankers(obj.key, e.target.name, e.target.value)}>
                                        <option value="0">Type...</option>
                                        <option value="1">Crude</option>
                                        <option value="2" disabled>Products</option>
                                        <option value="3" disabled>Chemical</option>
                                        <option value="4" disabled>LNG</option>
                                        <option value="5" disabled>LPG</option>
                                    </Form.Select>
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Select key={obj.key} name="seaTankerSize" onChange={(e) => updateSeaTankers(obj.key, e.target.name, e.target.value)}>
                                        <option value="0">Size...</option>
                                        <option value="0.00294" selected>200,000+ dwt</option>
                                        <option value="0.004461">120,000–199,999 dwt</option>
                                        <option value="0.005982">80,000–119,999 dwt</option>
                                        <option value="0.007604">60,000–79,999 dwt</option>
                                        <option value="0.009227">10,000–59,999 dwt</option>
                                        <option value="0.033763">0–9999 dwt</option>
                                        <option value="0.004572">Average</option>
                                    </Form.Select>
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Control type="number" min="1" key={obj.key} name="seaTankerLoad" placeholder={obj.seaTankerLoad} onChange={(e) => updateSeaTankers(obj.key, e.target.name, e.target.value)} />
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Control type="number" min="1" key={obj.key} name="seaTankerDistance" placeholder={obj.seaTankerDistance} onChange={(e) => updateSeaTankers(obj.key, e.target.name, e.target.value)} />
                                </Col>

                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <Badge pill bg="dark">{((obj.seaTankerSize * obj.seaTankerLoad * obj.seaTankerDistance) / 1000).toFixed(3)}</Badge>
                                    {/*<Form.Control type="number" min="1" name="co2" placeholder="0" value={((obj.seaTankerSize * obj.seaTankerLoad * obj.seaTankerDistance) / 1000).toFixed(3)} disabled />*/}
                                </Col>

                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <Badge pill bg="dark">${numberFormatter.format((((obj.seaTankerSize * obj.seaTankerLoad * obj.seaTankerDistance) / 1000) * pricePerTon).toFixed(2))}</Badge>
                                    {/*<Form.Control type="number" min="1" name="co2" placeholder="0" value={(((obj.seaTankerSize * obj.seaTankerLoad * obj.seaTankerDistance) / 1000) * pricePerTon).toFixed(2)} disabled />*/}
                                </Col>

                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <Button variant="danger" size="sm" href="#" onClick={() => removeSeaTankers(obj.key)}><i class="bi bi-x"></i></Button>
                                </Col>

                            </Row>

                        ))
                    }


                </fieldset>
            </Form>

            <br />

            {/* CARGO SHIPS*/}
            {/*<div className="text-left">
                <h6>
                    <span className=""><Badge pill bg="dark">{cargoShipsArray.length}</Badge>&nbsp;&nbsp;Shipment(s) by cargo ships</span>
                </h6>
            </div>*/}

            <Form className="form mb-4">
                <fieldset>
                    <Button variant="dark" size="sm" href="#" onClick={addCargoShips}>Add cargo shipment&nbsp;&nbsp;<i class="bi bi-box-seam"></i></Button>

                    <Row>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Type
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Size
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Load &#183; tonnes
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                            Distance &#183; kms
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                            CO2 <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                            $ <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                            </OverlayTrigger>
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                            <i class="bi bi-trash3"></i>
                        </Col>
                    </Row>

                    {
                        cargoShipsArray.map((obj) => (

                            <Row>
                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipShipmentViaCargoShip}><i class="bi bi-box-seam" style={{ "color": 'var(--main-font-color)' }}></i>
                                    </OverlayTrigger>
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Select key={obj.key} name="cargoShipType" value="1" onChange={(e) => updateCargoShips(obj.key, e.target.name, e.target.value)}>
                                        <option value="0">Type...</option>
                                        <option value="1">Bulk carrier</option>
                                        <option value="2" disabled>General cargo</option>
                                        <option value="3" disabled>Container ship</option>
                                        <option value="4" disabled>Vehicle transport</option>
                                        <option value="5" disabled>RoRo-Ferry</option>
                                        <option value="6" disabled>Large RoPax ferry</option>
                                        <option value="7" disabled>Refrigerated cargo</option>
                                    </Form.Select>
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Select key={obj.key} name="cargoShipSize" onChange={(e) => updateCargoShips(obj.key, e.target.name, e.target.value)}>
                                        <option value="0">Size...</option>
                                        <option value="0.002535" selected>200,000+ dwt</option>
                                        <option value="0.003042">100,000–199,999 dwt</option>
                                        <option value="0.004157">60,000–99,999 dwt</option>
                                        <option value="0.00578">35,000–59,999 dwt</option>
                                        <option value="0.00801">10,000–34,999 dwt</option>
                                        <option value="0.029607">0–9999 dwt</option>
                                        <option value="0.003539">Average</option>
                                    </Form.Select>
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Control type="number" min="1" key={obj.key} name="cargoShipLoad" placeholder={obj.cargoShipLoad} onChange={(e) => updateCargoShips(obj.key, e.target.name, e.target.value)} />
                                </Col>

                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                    <Form.Control type="number" min="1" key={obj.key} name="cargoShipDistance" placeholder={obj.cargoShipDistance} onChange={(e) => updateCargoShips(obj.key, e.target.name, e.target.value)} />
                                </Col>

                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <Badge pill bg="dark">{((obj.cargoShipSize * obj.cargoShipLoad * obj.cargoShipDistance) / 1000).toFixed(3)}</Badge>
                                    {/*<Form.Control type="number" min="1" name="co2" placeholder="0" value={((obj.cargoShipSize * obj.cargoShipLoad * obj.cargoShipDistance) / 1000).toFixed(3)} disabled />*/}
                                </Col>

                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <Badge pill bg="dark">${numberFormatter.format((((obj.cargoShipSize * obj.cargoShipLoad * obj.cargoShipDistance) / 1000) * pricePerTon).toFixed(2))}</Badge>
                                    {/*<Form.Control type="number" min="1" name="co2" placeholder="0" value={(((obj.cargoShipSize * obj.cargoShipLoad * obj.cargoShipDistance) / 1000) * pricePerTon).toFixed(2)} disabled />*/}
                                </Col>

                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <Button variant="danger" size="sm" href="#" onClick={() => removeCargoShips(obj.key)}><i class="bi bi-x"></i></Button>
                                </Col>

                            </Row>

                        ))
                    }

                </fieldset>
            </Form>



            {/* AIR FREIGHTS
            <div className="text-left">
                <h6>
                    <span className=""><Badge pill bg="dark">0</Badge>&nbsp;&nbsp;Shipment(s) by air freights&nbsp;&nbsp;<i class="bi bi-wind"></i>&nbsp;&nbsp;|&nbsp;&nbsp;coming up soon...</span>
                </h6>
            </div>*/}


            <br /><br />


            {/*<div className="text-left">
                <h6>
                    <span className="">Compensating <Badge bg="dark">{calculateSumCo2().toFixed(3)} tons</Badge> of CO2 on behalf of your client or company</span>
                </h6>
            </div>*/}

            <Form className="form mb-4">
                <fieldset>

                    <div class="row">

                        <div class="col-12">
                            <label for="company" class="form-label">Client or company name to be displayed on the certificate {/*<span class="text-muted">(Optional)</span>*/}</label>
                            <input type="text" class="form-control" id="company" placeholder="" required="" name="company" value={fullAddress.company} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Please enter a valid company name.
                            </div>
                        </div>

                        <div class="col-12">
                            <label for="email" class="form-label">Email {/*<span class="text-muted">(Optional)</span>*/}</label>
                            <input type="email" class="form-control" id="email" placeholder="" required="" name="email" value={fullAddress.email} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Please enter a valid email address.
                            </div>
                            <br />
                        </div>


                        {/*<div class="row">

                            <div class="col-lg-6">

                                <Form.Check
                                    type="switch"
                                    id="custom-switch-offsetting"
                                    label="Carbon compensate your client's shipments"
                                    name="offsetting"
                                    disabled="disabled"
                                    defaultChecked={state.offsetting ? "checked" : ""}
                                    value={state.offsetting}

                                //onChange={handleChangeOffsetting}
                                />

                            </div>
                            <div class="col-lg-6">

                                <Form.Check
                                    type="switch"
                                    id="custom-switch-certificate"
                                    label="Email carbon neutral certificate"
                                    defaultChecked="checked"
                                    disabled="disabled"
                                />

                                <br />
                            </div>

                        </div>*/}


                        <div className="text-center">
                            {/*<h4>
                                    <Badge bg="dark" text="light" as="Button" class="w-100 btn btn-primary btn-lg" type="submit">Continue to checkout</Badge>
                            </h4>*/}
                            {/*<Button class="btnSubmit"
                                disabled={isLoading}
                                onClick={!isLoading ? handleClick : null}
                            >
                                {isLoading ? 'Processing payment…' : checkoutButtonText}
                        </Button>*/}
                            <input class="btnSubmit" type="button" onClick={!isLoading ? handleClickOnCompensate : null} value={isLoading ? '  Processing…  ' : ' Compensate ' + [calculateSumCo2().toFixed(3)] + ' tons of CO2 for $' + numberFormatter.format([(calculateSumCo2() * pricePerTon).toFixed(2)]) + ' '} disabled={isSubmittedStatus == 1 ? 'disabled' : ''} />
                        </div>

                    </div>

                </fieldset>
            </Form>


            {isSubmittedStatus == 0 ?
                <Alert key='danger' variant='danger'>
                    Please provide your <b>client or company name</b> and <b>email</b> before compensating shipments.
                </Alert>
                :
                null
            }

            {isSubmittedStatus == 1 ?
                <Alert key='success' variant='success'>
                    Thank you for your compensation.
                    <br /><br />You will receive your carbon neutral certificate per email in a few minutes.
                </Alert>
                :
                null
            }

        </>
    )
}

export default CalculatorFormOffsettingToolFreight