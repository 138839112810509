// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************

import React, { useEffect, useState } from "react"
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function YourCartCarFleet(props) {



    // Retrieving values from parent
    var distanceInKm = props.distanceInKm
    var cabinClass = props.cabinClass
    var offsetting = props.offsetting
    var compensation = props.compensation
    var pricePerTon = props.pricePerTon
    var carbonTons = props.carbonTons
    var flightPrice = props.flightPrice

    var fleetType = props.fleetTypePassedOn
    var fleetSize = Math.round(props.fleetSizePassedOn)
    var carbonTonsUpdated = carbonTons

    var estimatedEmissions = 0
    var totalCompensation = ""
    var totalFlightPrice =0


    const numberFormatter = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
      });


    if (fleetType == "Compact") {
        flightPrice = flightPrice
        totalFlightPrice = (fleetSize * flightPrice).toFixed(2)
        totalCompensation = (fleetSize * compensation)
        carbonTonsUpdated = carbonTons
        estimatedEmissions = (fleetSize * carbonTonsUpdated)

    }
    else if (fleetType == "MidSize") {
        flightPrice = (flightPrice * 1.5)
        totalFlightPrice = (fleetSize * flightPrice).toFixed(2)
        totalCompensation = (fleetSize * compensation * 1.3)
        carbonTonsUpdated = (carbonTons * 1.3)
        estimatedEmissions = (fleetSize * carbonTonsUpdated)
    }
    else {
        flightPrice = (flightPrice * 3)
        totalFlightPrice = (fleetSize * flightPrice).toFixed(2)
        totalCompensation = (fleetSize * compensation * 1.6)
        carbonTonsUpdated = (carbonTons * 1.6)
        estimatedEmissions = (fleetSize * carbonTonsUpdated)

    }






    var total = 0;


    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Emissions are calculated based your fleet size and type of vehicles.
        </Tooltip>
    );

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            By compensating your fleet order, you support the emissions reduction projects.
        </Tooltip>
    );

    const renderTooltipCapeTown = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Tooltip text 1
        </Tooltip>
    );

    const renderTooltipRotterdam = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Tooltip text 2
        </Tooltip>
    );



    return (
        <>

            <div class="text-center">
                <h6>
                    <span class="">Your cart</span>
                </h6>
            </div>

            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    {/*<div class="col-sm-3">
                                <input type="number" class="form-control" id="fleetSize" placeholder="" required="" name="fleetSize" value={fleetSize} />
    </div>*/}

                    <div class="ms-2 me-auto">
                        <div className="fw-bold text-offsetting">{fleetSize} {fleetType} vehicles</div>
                        ${numberFormatter.format(flightPrice)}: vehicle price per unit
                    </div>

                    <span>${numberFormatter.format(fleetSize * flightPrice)}</span>


                    {/*<span id="flight1">${flightPrice}</span>*/}
                </ListGroup.Item>

                {!offsetting ? null :

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">Estimated emissions <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                            Offsetting your yearly emissions
                            <br />{carbonTonsUpdated.toFixed(2)} tons of CO2 per vehicle per year
                        </div>
                        <span class="text-offsetting">{numberFormatter.format(estimatedEmissions)} tons</span>
                    </ListGroup.Item>
                }

                {!offsetting ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">CO2 compensation <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                        </div>
                        <span class="text-offsetting">${numberFormatter.format(totalCompensation)}</span>
                    </ListGroup.Item>
                }

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Total</div>
                    </div>
                    <strong><span name="total" value={numberFormatter.format(parseFloat(totalFlightPrice) + parseFloat(totalCompensation))}>${offsetting ? numberFormatter.format(parseFloat(totalFlightPrice) + parseFloat(totalCompensation)) : numberFormatter.format(parseFloat(totalFlightPrice))}</span></strong>
                </ListGroup.Item>


            </ListGroup>
            <br />

        </>
    )
}

export default YourCartCarFleet