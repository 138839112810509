// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************

import React, { useEffect, useState } from "react"
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';


import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function YourCartAirline(props) {
    // JSON data retrieved from API
    //const [calculation, setCalculation] = useState([])

    // Retrieving values from parent
    var distanceInKm = props.distanceInKm
    var cabinClass = props.cabinClass
    var offsetting = props.offsetting
    var compensation = props.compensation
    var pricePerTon = props.pricePerTon
    var carbonTons = props.carbonTons
    var flightPrice = props.flightPrice

    var total = 0;



    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Emissions are calculated for one passenger in your selected seating class (for {distanceInKm} kms).
        </Tooltip>
    );

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            By compensating your journey, you support the emissions reduction projects.
        </Tooltip>
    );

    const renderTooltipCapeTown = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Home team
        </Tooltip>
    );

    const renderTooltipRotterdam = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Away team
        </Tooltip>
    );



    return (
        <>

            <div class="text-center">
                <h6>
                    <span class="">Your order</span>
                </h6>
            </div>


            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        {/*<div className="fw-bold">Your final order</div>*/}
                        <h6 class="my-0">Flight <span class="badgeFlight">GVA</span> <i class="bi bi-arrow-right"></i> <span class="badgeFlight">LHR</span></h6>
                        <small class="text-muted">LX324 - Economy</small>
                    </div>
                    <span id="flight1">${flightPrice}</span>
                </ListGroup.Item>

                {!offsetting ? null :

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">Estimated emissions <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger>
                            </div>
                            Offsetting your flight journey

                        </div>
                        <span class="text-offsetting">{carbonTons} tons</span>
                    </ListGroup.Item>
                }

                {!offsetting ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">CO2 compensation <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                        </div>
                        <span class="text-offsetting">${compensation}</span>
                    </ListGroup.Item>
                }

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Total</div>
                    </div>
                    <strong><span name="total" value={flightPrice + parseFloat(compensation)}>${offsetting ? flightPrice + parseFloat(compensation) : flightPrice}</span></strong>
                </ListGroup.Item>


            </ListGroup>
            <br />

        </>
    )
}

export default YourCartAirline