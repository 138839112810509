// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************

import React, { useEffect, useState } from "react"
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';


import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function YourCartFootball(props) {
    // JSON data retrieved from API
    //const [calculation, setCalculation] = useState([])

    // Retrieving values from parent
    var distanceInKm = props.distanceInKm
    var cabinClass = props.cabinClass
    var offsetting = props.offsetting
    var compensation = props.compensation
    var pricePerTon = props.pricePerTon
    var carbonTons = props.carbonTons
    var flightPrice = props.flightPrice

    // Declaring variables
    //var flightPrice = 154;
    //var compensation = 0;
    var total = 0;




    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Emissions are calculated based on the round-trip distance ({distanceInKm} kms) from your address to the stadium.
        </Tooltip>
    );

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            By compensating your journey, you support the emissions reduction projects.
        </Tooltip>
    );

    const renderTooltipCapeTown = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Home team
        </Tooltip>
    );

    const renderTooltipRotterdam = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Away team
        </Tooltip>
    );


    // Initiating variables
    // Setting original values (it should pick the data from the DB or the prior day)
    /*const [state, setState] = useState({
        flightPrice: flightPrice,
        //compensation: calculation['total_cost'],
        total: flightPrice + parseFloat(compensation)
    })
*/






    /*var optionsApiCalculate = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CPB0309E64DDD14AAE8C77EB10F6CF7C4B:ksDWvSy4N79j8OQ0s6tA1iTor2uDbWWl4oQXKS9bGrjaw2ZRW4YRe8sBNZ7cDatW',
            'Host': 'https://commerce.climatepositive.com/api/v1/calculate',
        },
        body: JSON.stringify({
            //'client_id': 'CPB0309E64DDD14AAE8C77EB10F6CF7C4B',
            //'client_secret': 'ksDWvSy4N79j8OQ0s6tA1iTor2uDbWWl4oQXKS9bGrjaw2ZRW4YRe8sBNZ7cDatW',
            'calculator_id': 'CPFlightCalculator_237D',
            'distance': distanceInKm,
            'class': cabinClass
        })
    }*/



    /*const fetchDataCalculate = () => {
        fetch(`https://commerce.climatepositive.com/api/v1/calculate`, optionsApiCalculate)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                setCalculation(result.data.calculation)
            })
    }

    useEffect(() => {
        fetchDataCalculate()
    }, [])*/





    //useState();



    return (
        <>




            {/*<h2>
                Carbon tons: {calculation['carbon_tons']}
                <br/>Price per ton: {calculation['price_per_ton']}
                <br/>Total cost: {calculation['total_cost']}
    </h2>*/}

            <div class="text-center">
                <h6>
                    <span class="">Your order</span>
                </h6>
            </div>


            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        {/*<div className="fw-bold">Your final order</div>*/}
                        1 ticket for <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCapeTown}><span class="badgeFlight">Team #1</span></OverlayTrigger> - <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipRotterdam}><span class="badgeFlight">Team #2</span></OverlayTrigger>
                        <br /><i class="bi bi-geo-alt-fill"></i>&nbsp;&nbsp;Central stadium
                        <br /><i class="bi bi-clock-fill"></i>&nbsp;&nbsp;Sat 26 Aug 2023, 6.30pm
                    </div>
                    <span id="flight1">${flightPrice}</span>
                </ListGroup.Item>

                {!offsetting ? null :

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">Estimated emissions <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger>
                            </div>
                            Offsetting the journey from your address to the stadium

                        </div>
                        <span class="text-offsetting">{carbonTons} tons</span>
                    </ListGroup.Item>
                }

                {!offsetting ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">CO2 compensation <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                        </div>
                        <span class="text-offsetting">${compensation}</span>
                    </ListGroup.Item>
                }

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Total</div>
                    </div>
                    <strong><span name="total" value={flightPrice + parseFloat(compensation)}>${offsetting ? flightPrice + parseFloat(compensation) : flightPrice}</span></strong>
                </ListGroup.Item>


            </ListGroup>
            <br />

        </>
    )
}

export default YourCartFootball