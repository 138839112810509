// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************
import React, { useEffect, useState } from "react"

import Papa from "papaparse";

import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ReactCountryFlag from "react-country-flag";


// For multilingual capabilities
import { useTranslation } from 'react-i18next';
import i18n from './i18n'; // Import your i18n configuration
import { I18nextProvider } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faComputer } from '@fortawesome/free-solid-svg-icons'
import { faLaptop } from '@fortawesome/free-solid-svg-icons'
import { faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faDesktop } from '@fortawesome/free-solid-svg-icons'

import { faLeaf } from '@fortawesome/free-solid-svg-icons'


import GaugeChart from 'react-gauge-chart'
import ProgressBar from 'react-bootstrap/ProgressBar';


import mzFlag from '../img/country-flags-main/png100px/mz.png'
import inFlag from '../img/country-flags-main/png100px/in.png'
import brFlag from '../img/country-flags-main/png100px/br.png'
import idFlag from '../img/country-flags-main/png100px/id.png'
import trFlag from '../img/country-flags-main/png100px/tr.png'
import ugFlag from '../img/country-flags-main/png100px/ug.png'
import zaFlag from '../img/country-flags-main/png100px/za.png'


import sdg1 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-01.png'
import sdg2 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-02.png'
import sdg3 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-03.png'
import sdg4 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-04.png'
import sdg5 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-05.png'
import sdg6 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-06.png'
import sdg7 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-07.png'
import sdg8 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-08.png'
import sdg9 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-09.png'
import sdg10 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-10.png'
import sdg11 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-11.png'
import sdg12 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-12.png'
import sdg13 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-13.png'
import sdg14 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-14.png'
import sdg15 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-15.png'
import sdg16 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-16.png'
import sdg17 from '../img/SDGs/SDG Icons 2019_WEB/E-WEB-Goal-17.png'


import vcs0535 from '../marketing_material/PDF frontpage/VCS0535.png'
import gs447 from '../marketing_material/PDF frontpage/GS447.png'
import vcs674 from '../marketing_material/PDF frontpage/VCS674.png'
import gs5047 from '../marketing_material/PDF frontpage/GS5047.png'
import gs1134 from '../marketing_material/PDF frontpage/GS1134.png'


import gs447_picture from '../marketing_material/gs447_picture.png'
import gs447_picture2 from '../marketing_material/gs447_picture_2.png'

import vcs674_picture from '../marketing_material/vcs674_picture.png'
import vcs674_picture2 from '../marketing_material/vcs674_picture2.png'
import vcs674_picture3 from '../marketing_material/vcs674_picture3.png'

import vcs674_picture5 from '../marketing_material/vcs674_rimba-raya-river-web-scaled_2.jpg'
import vcs674_picture6 from '../marketing_material/vcs674_rimba-raya-mom-baby-climbing-web-1024x681_2.jpg'



import gs5047_picture from '../marketing_material/gs5047_picture.png'
import gs5047_picture2 from '../marketing_material/gs5047_picture2.png'
import gs5047_picture3 from '../marketing_material/gs5047_picture3.png'

import gs1134_picture from '../marketing_material/gs1134_picture.png'

import vcs1950_picture from '../marketing_material/original_vcs_1950_RubyJean-Mulilo-057_1_3.jpg'
import vcs1950_picture2 from '../marketing_material/original_vcs_1950_RubyJean-Mulilo-001_3.jpg'
import vcs1950_picture3 from '../marketing_material/original_vcs_1950_Longyuan_3.jpg'


import vcs1329_picture1 from '../marketing_material/VCS1329/vcs1329_1.jpg'
import vcs1329_picture2 from '../marketing_material/VCS1329/vcs1329_2.jpg'
import vcs1329_picture3 from '../marketing_material/VCS1329/vcs1329_3.jpg'

import vcs981_picture1 from '../marketing_material/VCS981/vcs981_1.jpg'
import vcs981_picture2 from '../marketing_material/VCS981/vcs981_2.jpg'

import vcs2252_picture1 from '../marketing_material/VCS2252/vcs2252_1.jpg'

import gs7599_picture1 from '../marketing_material/GS7599/gs7599_1.jpg'
import gs7599_picture2 from '../marketing_material/GS7599/gs7599_2.jpg'

import gs7132_picture1 from '../marketing_material/GS7132/gs7132_1.jpg'
import gs7132_picture2 from '../marketing_material/GS7132/gs7132_2.jpg'



//import CSVReader from './CSVReader';


const chartStyle = {
    height: 100,
}

// ************************************************************
// ************************************************************
// Number formatting: thousand separator and 2 decimals
// ************************************************************
// ************************************************************
const numberFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});


const numberFormatterInteger = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

/**************************************************************/
/**************************************************************/
/*************** Simulate Network Request / timeout ***********/
/**************************************************************/
/**************************************************************/
function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}


function CalculatorOffsettingToolIT(props) {

    const [sumEmissionsProject1, setSumEmissionsProject1] = useState(0);
    const [sumEmissionsProject2, setSumEmissionsProject2] = useState(0);
    const [sumEmissionsProject3, setSumEmissionsProject3] = useState(0);
    const [sumEmissionsProject4, setSumEmissionsProject4] = useState(0);


    // ************************************************************
    // ************************************************************
    // For multilingual features
    // ************************************************************
    // ************************************************************
    const { t, i18n } = useTranslation();



    // JSON data retrieved from API
    var [calculation, setCalculation] = useState([])
    const [carbonDataJson, setCarbonDataJson] = useState([])
    const [vending, setVending] = useState([])
    const [compensation, setCompensation] = useState([])
    const [pricePerTon, setPricePerTon] = useState(props.pricePerTon)

    //const [sumCo2, setSumCo2] = useState(0)




    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [fullAddress, setFullAddress] = useState({
        // This variable can be accessed as follow: state.inputVcs
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        address: "",
        country: ""
    })


    // Handling changes
    const handleFullAddress = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;
        setFullAddress({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...fullAddress,
            [name]: value
        })

    }


    // Regular expression for email validation
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const [valid, setValid] = useState(true);

    const handleEmailBlur = () => {
        // Check email validity when leaving the field
        const isValid = emailRegex.test(fullAddress.email);
        setValid(isValid);
    };



    // ************************************************************
    // ************************************************************
    // Pop-ups
    // ************************************************************
    // ************************************************************

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            {t('co2_compensation_in_usd')}{pricePerTon}
        </Tooltip>
    );

    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            {t('emissions_in_kgs')}
        </Tooltip>
    );


    const renderTooltipLeasePeriodMonth = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            {t('lease_period_in_month')}
        </Tooltip>
    );


    const phaseToDisplay = {
        production_phase: t('production_phase'),
        transportation_phase: t('transportation_phase'),
        use_phase: t('use_phase'),
        end_of_life_phase: t('end_of_life_phase'),
        add_production_phase: t('add_production_phase'),
        add_transportation_phase: t('add_transportation_phase'),
        add_use_phase: t('add_use_phase'),
        add_end_of_life_phase: t('add_end_of_life_phase'),
        add_computer: t('add_computer'),
        add_laptop: t('add_laptop'),
        add_server: t('add_server'),
        add_smartphone: t('add_smartphone'),
        add_printer: t('add_printer'),
        add_monitor: t('add_monitor')
    };


    const renderTooltipProductionPhase = (
        <Tooltip id="button-tooltip">
            {t('production_phase')}
        </Tooltip>
    );

    const tooltip_production_phase = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            {t('production_phase')}
        </Tooltip>
    );


    const renderTooltipTransportationPhase = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            {t('transportation_phase')}
        </Tooltip>
    );

    const renderTooltipUsePhase = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            {t('use_phase')}
        </Tooltip>
    );

    const renderTooltipCertificate = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Get a certificate per email (free).
        </Tooltip>
    );

    const renderTooltipOffsetting = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <strong>Offset </strong> your <strong>flight</strong>!
        </Tooltip>
    );

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Projects you will support</Popover.Header>
            <Popover.Body>
                Do the right thing by <strong>offsetting</strong> your <strong>flight</strong>.
            </Popover.Body>
        </Popover>
    );


    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    /*const [state, setStateOffsetting] = useState({
        // This variable can be accessed as follow: state.inputVcs
        offsetting: true,
    })*/

    // Handling changes
    /*const handleChangeOffsetting = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value =
            evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setStateOffsetting({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);
    }*/


    /**************************************************************/
    /**************************************************************/
    /*************** Triggered when Submit button*********************************/
    /**************************************************************/
    /**************************************************************/
    const [isSubmittedStatus, setSubmittedOrderStatus] = useState(-1) // -1 = initial state (no message to be displayed)

    // Loading button
    const [isLoading, setLoading] = useState(false);


    const handleClickOnCompensate = () => {
        setLoading(true);

        fullAddress.email = "sebastien.roh@starcb.com"
        fullAddress.company = "Bulk offsetting | demo"

        simulateNetworkRequest().then(() => {

            if (fullAddress.email.includes('@') & fullAddress.company.length > 0) {
                setSubmittedOrderStatus(1)
                setFullAddress({ ['company']: '' })
                setFullAddress({ ['email']: '' })

                // If Compact vehicle select, then proceed
                /*if (stateFleetType.compactOffered) {
                    fetchDataCalculate()
                    fetchDataVend()
                }*/

            } else {
                setSubmittedOrderStatus(0)
            }
        });
    }


    const [projectsArray, setProjectsArray] = useState([
        { projectID: 0, name: "Pick a project...", countryName: "", isoCode3: "", originalVolume: "10000", actualVolume: "1000", percentage: "0.1" },
        { projectID: 1, name: "Biodiversity Reserve Project", countryName: "Indonesia", isoCode3: "IDN", originalVolume: "10000", actualVolume: "2000", percentage: "0.2" },
        { projectID: 2, name: "Longyuan Mulilo North Wind Facility", countryName: "South Africa", isoCode3: "RSA", originalVolume: "15000", actualVolume: "3000", percentage: "0.3" },
        { projectID: 3, name: "Mozambique Safe Water Project", countryName: "Mozambique", isoCode3: "MOZ", originalVolume: "20000", actualVolume: "4000", percentage: "0.4" },
        { projectID: 4, name: "Windkraftprojekt in Karnataka von OMWPL", countryName: "India", isoCode3: "IND", originalVolume: "25000", actualVolume: "5000", percentage: "0.5" }
    ])

    {/*const [globalArray, setGlobalArray] = useState([{ tooltip: "tooltip_production_phase", phaseID: "add_computer", phase: " computer phase", bootstrapIconName: "faComputer", size: "1", load: "0.27", projectID: "0" }])



    const addEntry = (tooltipInput, phaseID, phaseName, bootstrapIconName, projectID) => {
        const newObject = { key: Date.now(), tooltip: tooltipInput, phaseID: phaseID, phase: phaseName, bootstrapIconName: bootstrapIconName, size: "1", load: "0.27", projectID: projectID };
        setGlobalArray([...globalArray, newObject]);

    }

    const updateEntry = (key, newName, newValue) => {

        const updatedArray = globalArray.map((obj) =>
            // [newName] --> to specify the property name to update
            obj.key === key ? { ...obj, [newName]: newValue } : obj
        );
        setGlobalArray(updatedArray);
    }


    useEffect(() => {

        setSumEmissionsProject1(globalArray.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "1") {
                return accumulator + (currentValue.load * currentValue.size);
            } else {
                return accumulator;
            }
        }, 0))

        setSumEmissionsProject2(globalArray.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "2") {
                return accumulator + (currentValue.load * currentValue.size);
            } else {
                return accumulator;
            }
        }, 0))

        setSumEmissionsProject3(globalArray.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "3") {
                return accumulator + (currentValue.load * currentValue.size);
            } else {
                return accumulator;
            }
        }, 0))

        setSumEmissionsProject4(globalArray.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "4") {
                return accumulator + (currentValue.load * currentValue.size);
            } else {
                return accumulator;
            }
        }, 0))


    }, [globalArray]); //The dependency array [globalArray] indicates that this effect should run whenever the value of globalArray changes.





    // Function to remove an object from the array by key
    const removeEntry = (key) => {
        const updatedArray = globalArray.filter((obj) => obj.key !== key);
        setGlobalArray(updatedArray);

    };


*/}


    // componentDidUpdate

    {/*}
    useEffect(() => {
        //const sumCo2 = calculateSumCo2();
        //setSumCo2(calculateSumCo2())

        //console.log('Sum of cargo ships CO2:', sumCo2);
    }, [transportationPhaseArray][productionPhaseArray]); // Specify the dependency (items) to trigger the effect when it changes

*/}





    /**************************************************************/
    /**************************************************************/
    /*************** API CALCULATE METHOD *************************/
    /**************************************************************/
    /**************************************************************/

    // Default optionsApiCalculate (will be replaced when clicked on Compensate to get the updated carbon_tons)
    {/*const [optionsApiCalculate, setOptionsApiCalculate] = useState(
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
                'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
                'Access-Control-Allow-Origin': '*',
                'mode': 'no-cors'
            },
            body: JSON.stringify({
                'calculator_id': 'SCBRawCarbon_DT34',
                'item_description': 'Bulk offsetting of freighting goods via the offsetting tool',
                'carbon_tons': 100
                //'quantity': 1
            })
        }
    )*/}


    var optionsApiCalculate = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
            'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            'calculator_id': 'SCBRawCarbon_DT34',
            'item_description': 'Bulk offsetting of freighting goods via the offsetting tool',
            'carbon_tons': 1
            //'quantity': 1
        })
    }




    useEffect(() => {

        fetchDataCalculate()

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            fetchDataVend();
        }

    }, [isLoading]);




    // Proceed each time isLoading = true or is amended
    {/*useEffect(() => {
        console.log("useEffect called. ")        

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            // Update the setOptionsApiCalculate with the latest carbon_tons (replacing the initial data)
            setOptionsApiCalculate({
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
                    'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
                    'Access-Control-Allow-Origin': '*',
                    'mode': 'no-cors'
                },
                body: JSON.stringify({
                    'calculator_id': 'SCBRawCarbon_DT34',
                    'item_description': 'Bulk offsetting of freighting goods via the offsetting tool',
                    'carbon_tons': 100
                })
            })            

            fetchDataCalculate()
            console.log("my order.fleetSizeCompact just before VENDING method:")

            fetchDataVend()
        }

    }, [isLoading]);*/}




    const fetchDataCalculate = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/calculate`, optionsApiCalculate)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                //setCarbonDataJson(result.data)
                setCalculation(result.data.calculation)
                //setCompensation(result.data.calculation.total_cost)
                //setPricePerTon(result.data.calculation.price_per_ton)
            })
    }


    /**************************************************************/
    /**************************************************************/
    /*************** API VEND METHOD ******************************/
    /**************************************************************/
    /**************************************************************/
    var optionsApiVend = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
            'Host': 'https://carboncompensate.starcb.com/api/v1/vend',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            calculation,
            'customer_reference': '',
            'consumer_email': fullAddress.email,
            'on_behalf_of_name': fullAddress.company
        })
    }

    const fetchDataVend = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/vend`, optionsApiVend)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                setVending(result.data)
            })
    }





    /**************************************************************/
    /**************************************************************/
    /*************** Choose file (CSV) ******************************/
    /**************************************************************/
    /**************************************************************/
    const [parsedData, setParsedData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [values, setValues] = useState([]);
    const changeHandler = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                const rowsArray = [];
                const valuesArray = [];
                results.data.map((d) => {
                    rowsArray.push(Object.keys(d));
                    valuesArray.push(Object.values(d));
                });
                setParsedData(results.data);
                setTableRows(rowsArray[0]);
                setValues(valuesArray);
            },
        });
    };




    const addEntryParsedData = (quantity, product, carbon_footprint_of_device_in_kg_over_lease_time, co2, projectID, offsetting_on_behalf_of, email) => {
        const newObject = { key: Date.now(), quantity: quantity, product: product, carbon_footprint_of_device_in_kg_over_lease_time: carbon_footprint_of_device_in_kg_over_lease_time, co2: co2, projectID: projectID, offsetting_on_behalf_of: offsetting_on_behalf_of, email: email };
        setParsedData([...parsedData, newObject]);
        //props.sendProductionPhaseArrayToParent(productionPhaseArray);
    };

    const updateEntryParsedData = (key, newName, newValue) => {

        const updatedArray = parsedData.map((obj) =>
            // [newName] --> to specify the property name to update
            obj.key === key ? { ...obj, [newName]: newValue } : obj
        );
        setParsedData(updatedArray);


        /*if (newName == 'projectID') {
            console.log("projectID:")
            console.log(newValue)
        }

        if (newName == 'load') {
            console.log("load selected")
            projectsArray[0].actualVolume = parseFloat(projectsArray[0].actualVolume) + parseFloat(newValue);

            projectsArray[0].percentage = parseFloat(projectsArray[0].actualVolume / projectsArray[0].originalVolume);

            console.log("Percentage proj0:")
            console.log(projectsArray[0].percentage)
        }*/

        {/*{globalArray.map((object) => (
            if (object.projectID==1){
                projectsArray[1].actualVolume = projectsArray[1].actualVolume + object.load * object.size;
            }

        ))}*/}

    }

    // Function to remove an object from the array by key
    const removeEntryParsedData = (key) => {
        const updatedArray = parsedData.filter((obj) => obj.key !== key);
        setParsedData(updatedArray);

        //props.sendProductionPhaseArrayToParent(updatedArray);
    };




    console.log("parsedData: ")
    console.log(parsedData)


    /**************************************************************/
    /**************************************************************/
    /* Calculate CO2 emissions and compensations
    /**************************************************************/
    /**************************************************************/

    const calculateSumCo2 = () => {
        //const { items } = this.state;
        // Using the reduce function to calculate the sum
        //const sumCargoShips = transportationPhaseArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.cargoShipSize) * parseFloat(currentItem.cargoShipLoad)), 0);
        //const sumSeaTankers = productionPhaseArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.seaTankerSize) * parseFloat(currentItem.seaTankerLoad)), 0);
        //const sumUsePhase = usePhaseArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.usePhaseSize) * parseFloat(currentItem.usePhaseLoad)), 0);
        const sumGlobalArrayParsedData = parsedData.reduce((acc, currentItem) => acc + (((parseFloat(currentItem.quantity) * parseFloat(currentItem.carbon_footprint_of_device_in_kg_over_lease_time)) / currentItem.lease_period_in_month) * 3), 0);

        //props.sendSumCo2(sumCargoShips + sumSeaTankers + sumUsePhase + sumGlobalArray);
        props.sendSumCo2(sumGlobalArrayParsedData);

        //const sumSeaTankers = productionPhaseArray.reduce((acc, currentItem) => acc + (parseFloat(currentItem.SeaTankerSize)*parseFloat(currentItem.SeaTankerLoad)*parseFloat(currentItem.SeaTankerDistance)/1000.0), 0);
        //return sumCargoShips + sumSeaTankers + sumUsePhase + sumGlobalArray;
        return sumGlobalArrayParsedData;
    };



    useEffect(() => {

        setSumEmissionsProject1(parsedData.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "1") {
                return accumulator + ((currentValue.carbon_footprint_of_device_in_kg_over_lease_time * currentValue.quantity) / currentValue.lease_period_in_month) * 3;
            } else {
                return accumulator;
            }
        }, 0))

        setSumEmissionsProject2(parsedData.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "2") {
                return accumulator + ((currentValue.carbon_footprint_of_device_in_kg_over_lease_time * currentValue.quantity) / currentValue.lease_period_in_month) * 3;
            } else {
                return accumulator;
            }
        }, 0))

        setSumEmissionsProject3(parsedData.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "3") {
                return accumulator + ((currentValue.carbon_footprint_of_device_in_kg_over_lease_time * currentValue.quantity) / currentValue.lease_period_in_month) * 3;
            } else {
                return accumulator;
            }
        }, 0))

        setSumEmissionsProject4(parsedData.reduce((accumulator, currentValue) => {
            if (currentValue.projectID === "4") {
                return accumulator + ((currentValue.carbon_footprint_of_device_in_kg_over_lease_time * currentValue.quantity) / currentValue.lease_period_in_month) * 3;
            } else {
                return accumulator;
            }
        }, 0))


    }, [parsedData]); //The dependency array [globalArray] indicates that this effect should run whenever the value of globalArray changes.



    return (
        <>

            {/* SEA TANKERS*/}
            {/*<div className="text-left">
                <h6>
                    <span className=""><Badge pill bg="dark">{productionPhaseArray.length}</Badge>&nbsp;&nbsp;Shipment(s) by sea tankers</span>
                </h6>
            </div>*/}

            {/*<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_production_phase", "add_production_phase", " production phase", "bi bi-hammer")}><i class="bi bi-hammer"></i>&nbsp;&nbsp;{t('production_phase')}</Button>
            &nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_transportation_phase", "add_transportation_phase", " transportation phase", "bi bi-truck")}><i class="bi bi-truck"></i>&nbsp;&nbsp;{t('transportation_phase')}</Button>
            &nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_use_phase", "add_use_phase", " use phase", "bi bi-fan")}><i class="bi bi-fan"></i>&nbsp;&nbsp;{t('use_phase')}</Button>
            &nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_end_of_life_phase", "add_end_of_life_phase", " enf-of-life phase", "bi bi-recycle")}><i class="bi bi-recycle"></i>&nbsp;&nbsp;{t('end_of_life_phase')}</Button>
            <br /><br />*/}
            {/*&nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_end_of_life_phase", "add_computer", " enf-of-life phase", "faComputer", "0")}><i class="bi bi-plus-circle-fill"></i>&nbsp;&nbsp;{t('add_computer')}</Button>
            &nbsp;&nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_end_of_life_phase", "add_laptop", " enf-of-life phase", "faLaptop", "0")}><i class="bi bi-plus-circle-fill"></i>&nbsp;&nbsp;{t('add_laptop')}</Button>
            &nbsp;&nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_end_of_life_phase", "add_server", " enf-of-life phase", "faDatabase", "0")}><i class="bi bi-plus-circle-fill"></i>&nbsp;&nbsp;{t('add_server')}</Button>
            &nbsp;&nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_end_of_life_phase", "add_smartphone", " enf-of-life phase", "faMobileScreenButton", "0")}><i class="bi bi-plus-circle-fill"></i>&nbsp;&nbsp;{t('add_smartphone')}</Button>
            &nbsp;&nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntry("tooltip_end_of_life_phase", "add_printer", " enf-of-life phase", "faPrint", "0")}><i class="bi bi-plus-circle-fill"></i>&nbsp;&nbsp;{t('add_printer')}</Button>*/}
            <br />

            {/*&nbsp;<Button variant="dark" size="sm" href="#" onClick={() => addEntryParsedData(1, "Computer", "0.27", "0.27", "1", "___________", "test@test.com")}><i class="bi bi-plus-circle-fill"></i>&nbsp;&nbsp;{t('add_computer')}</Button>*/}

            <Row>
                <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9} style={{ "textAlign": "center" }}>

                    {/*<Form className="form mb-4">
                        <fieldset>
                            <Row>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    {t('size')}
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    {t('product')}
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    {t('co2_emissions_per_device')}
                                </Col>
                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    CO2 <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                                    </OverlayTrigger>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    {t('project')}
                                </Col>
                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                    <i class="bi bi-trash3"></i>
                                </Col>
                            </Row>

                            {
                                globalArray.map((obj) => (

                                    <Row>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                            <Form.Control type="number" min="1" key={obj.key} name="size" placeholder={obj.quantity} onChange={(e) => updateEntry(obj.key, e.target.name, e.target.value)} />
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "left", "height": "2.5em" }}>
                                            {phaseToDisplay[obj.phaseID]} <i class={obj.bootstrapIconName} style={{ "color": 'var(--main-font-color)' }}></i>
                                        </Col>
                                        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                            <Form.Control type="number" min="1" key={obj.key} name="load" placeholder={obj.load} onChange={(e) => updateEntry(obj.key, e.target.name, e.target.value)} />
                                        </Col>
                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                            <Badge bg="dark">{numberFormatter.format(((obj.quantity * obj.load)).toFixed(3))}</Badge>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{ "textAlign": "center", "height": "2.5em" }}>
                                            <Form.Select key={obj.key} name="projectID" onChange={(e) => updateEntry(obj.key, e.target.name, e.target.value)}>
                                                {projectsArray.map((obj) => (
                                                    <option value={obj.projectID}>{obj.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                            <Button variant="danger" size="sm" href="#" onClick={() => removeEntry(obj.key)}><i class="bi bi-x"></i></Button>
                                        </Col>
                                    </Row>

                                ))
                            }

                        </fieldset>
                    </Form>*/}


                    <input
                        type="file"
                        name="file"
                        onChange={changeHandler}
                        accept=".csv"
                        style={{ display: "block", margin: "0px auto" }}
                    /><br />


                    <Form className="form mb-4">
                        <Row style={{ "height": "3.5em" }}>

                            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>{t('description')}</b>
                            </Col>

                            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>{t('co2_per_device')}</b> <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                                </OverlayTrigger>
                            </Col>


                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>{t('quantity')}</b>
                            </Col>


                            {/*<Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "textAlign": "center", "height": "2.5em" }}>
                            {t('phase')}
        </Col>*/}


                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>Total CO2</b> <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                                </OverlayTrigger>
                            </Col>

                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>{t('lease_period')}</b> <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipLeasePeriodMonth}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                                </OverlayTrigger>
                            </Col>

                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>Total CO2 / quarter</b> <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                                </OverlayTrigger>
                            </Col>

                            <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>{t('project')}</b>
                                {/*{t('cost')} <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                            </OverlayTrigger>*/}
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <b>{t('client')}</b>
                                {/*{t('cost')} <OverlayTrigger trigger="hover" class="font" placement="top" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-font-color)' }}></i>
                            </OverlayTrigger>*/}
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center", "height": "2.5em" }}>
                                <i class="bi bi-trash3"></i>
                            </Col>
                        </Row>

                        {


                            parsedData.map((obj) => (

                                <Row>

                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "left" }}>
                                        <Badge bg="dark">{obj.asset_class}{/*<FontAwesomeIcon icon={faComputer} />*/}</Badge> {obj.description}{/*<FontAwesomeIcon icon={faComputer} />*/}
                                    </Col>

                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "2.5em" }}>
                                        <Badge bg="danger">{numberFormatter.format(obj.carbon_footprint_of_device_in_kg_over_lease_time)}</Badge>
                                    </Col>

                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "height": "2.5em" }}>
                                        <Form.Control type="number" min="1" key={obj.key} name="quantity" placeholder={obj.quantity} onChange={(e) => updateEntryParsedData(obj.key, e.target.name, e.target.value)} />
                                    </Col>

                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center" }}>
                                        <Badge bg="danger">{numberFormatter.format(((obj.quantity * obj.carbon_footprint_of_device_in_kg_over_lease_time)).toFixed(3))}</Badge>
                                        {/*<Form.Control type="number" min="1" name="co2" placeholder="0" value={((obj.seaTankerSize * obj.seaTankerLoad * obj.seaTankerDistance) / 1000).toFixed(3)} disabled />*/}
                                    </Col>

                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center" }}>
                                        {obj.lease_period_in_month}{/*<FontAwesomeIcon icon={faComputer} />*/}
                                    </Col>


                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center" }}>
                                        <Badge bg="danger">{numberFormatter.format((((obj.quantity * obj.carbon_footprint_of_device_in_kg_over_lease_time) / obj.lease_period_in_month) * 3).toFixed(3))}</Badge>
                                        {/*<Form.Control type="number" min="1" name="co2" placeholder="0" value={((obj.seaTankerSize * obj.seaTankerLoad * obj.seaTankerDistance) / 1000).toFixed(3)} disabled />*/}
                                    </Col>

                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "textAlign": "center" }}>
                                        {/*<Badge bg="dark">${numberFormatter.format((((obj.quantity * obj.load)) * pricePerTon).toFixed(2))}</Badge>*/}
                                        <Form.Select key={obj.key} name="projectID" onChange={(e) => updateEntryParsedData(obj.key, e.target.name, e.target.value)}>

                                            {projectsArray.map((project) => (
                                                obj.projectID.toString() == project.projectID.toString() ?
                                                    <option value={project.projectID} selected>{project.name}</option>
                                                    :
                                                    <option value={project.projectID}>{project.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>

                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center" }}>
                                        {obj.offsetting_on_behalf_of}
                                        {/*<Form.Control type="text" key={obj.key} name="offsetting_on_behalf_of" placeholder={obj.offsetting_on_behalf_of} onChange={(e) => updateEntryParsedData(obj.key, e.target.name, e.target.value)} />*/}
                                    </Col>

                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "textAlign": "center" }}>
                                        <Button variant="dark" size="sm" href="#" onClick={() => removeEntryParsedData(obj.key)}><i class="bi bi-x"></i></Button>
                                    </Col>

                                </Row>

                            ))

                        }

                    </Form>


                </Col>



                <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "textAlign": "left" }}>




                    <Col className="" style={{ "textAlign": "left" }}>
                        {/*<label for="company" class="form-label">{t('offsetting_on_behalf_of')}</label>
                        <Form.Control type="text" className="form-control" id="company" placeholder="" required="" name="company" value={fullAddress.company} onChange={handleFullAddress} />

                        <label for="email" class="form-label">{t('email')}</label>
                        <Form.Control type="email" className="form-control" id="email" placeholder="" required="" name="email" value={fullAddress.email} onChange={handleFullAddress} onBlur={handleEmailBlur} />
                        {!valid && <span style={{ color: '#dc3545' }}><i>{t('invalid_email_address')}</i></span>}
                        <br />*/}

                        {/*<input class="btnSubmit" type="button" onClick={!isLoading ? handleClickOnCompensate : null} value={isLoading ? [t('processing')] : [t('button_compensate')] + [calculateSumCo2().toFixed(2)] + [t('button_tons_of_co2')] + numberFormatter.format([(calculateSumCo2() * pricePerTon).toFixed(2)]) + ' '} disabled={isSubmittedStatus == 1 ? 'disabled' : ''} />*/}
                        {/*<input class="btnSubmit" size="sm" type="button" onClick={!isLoading ? handleClickOnCompensate : null} value={isLoading ? [t('processing')] : [t('button_compensate')] + [numberFormatter.format(calculateSumCo2().toFixed(2))] + [t('button_tons_of_co2')]} disabled={isSubmittedStatus == 1 ? 'disabled' : ''} />*/}

                        <div className="d-grid gap-2">

                            {(calculateSumCo2() > 0) ?
                                <>
                                <br/><br/>
                                    <Button variant="warning" size="lg" onClick={!isLoading ? handleClickOnCompensate : null} disabled={isSubmittedStatus == 1 ? 'disabled' : ''}>
                                        <FontAwesomeIcon fade icon={faLeaf} />&nbsp;{isLoading ? [t('processing')] : [t('button_compensate')] + [numberFormatter.format(calculateSumCo2().toFixed(2))] + [t('button_kgs_of_co2')]}
                                    </Button>

                                </>
                                :
                                <>
                                <br/>
                                </>
                            }

                            {isSubmittedStatus == 0 ?
                                <span style={{ color: '#dc3545' }}><i class="bi bi-x-circle-fill"></i> {t('error_message')}</span>
                                :
                                null
                            }

                            {isSubmittedStatus == 1 ?
                                <span style={{ color: 'var(--bootstrapSuccess)' }}><i class="bi bi-check-circle-fill"></i> {t('thank_you_message')}</span>
                                :
                                null
                            }
                        </div>

                        <br/>
                    </Col>
                    
                    <Col className="form" style={{ "textAlign": "left" }}>

                        

                        {/*<ProgressBar animated now={100 * (sumEmissionsProject1 / projectsArray[1].originalVolume)} label={`${100 * (sumEmissionsProject1 / projectsArray[1].originalVolume)}%`} />*/}
                        <span class="badge badgeProjectCountryHeader"><img height="10em" src={idFlag} />&nbsp;&nbsp;|&nbsp;&nbsp;{projectsArray[1].name}</span>
                        <ProgressBar>
                            <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject1 / projectsArray[1].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject1 / projectsArray[1].originalVolume))}%`} />
                            <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject1 / projectsArray[1].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject1 / projectsArray[1].originalVolume)))}%`} />
                        </ProgressBar>
                        <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject1)} {t('kilos_reserved')} ({numberFormatterInteger.format(projectsArray[1].originalVolume)} {t('pre_booked')})</span>
                        <br /><br />

                        <span class="badge badgeProjectCountryHeader"><img height="10em" src={zaFlag} />&nbsp;&nbsp;|&nbsp;&nbsp;{projectsArray[2].name}</span>
                        <ProgressBar>
                            <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject2 / projectsArray[2].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject2 / projectsArray[2].originalVolume))}%`} />
                            <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject2 / projectsArray[2].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject2 / projectsArray[2].originalVolume)))}%`} />
                        </ProgressBar>
                        <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject2)} {t('kilos_reserved')} ({numberFormatterInteger.format(projectsArray[2].originalVolume)} {t('pre_booked')})</span>
                        <br /><br />

                        <span class="badge badgeProjectCountryHeader"><img height="10em" src={mzFlag} />&nbsp;&nbsp;|&nbsp;&nbsp;{projectsArray[3].name}</span>
                        <ProgressBar>
                            <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject3 / projectsArray[3].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject3 / projectsArray[3].originalVolume))}%`} />
                            <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject3 / projectsArray[3].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject3 / projectsArray[3].originalVolume)))}%`} />
                        </ProgressBar>
                        <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject3)} {t('kilos_reserved')} ({numberFormatterInteger.format(projectsArray[3].originalVolume)} {t('pre_booked')})</span>
                        <br /><br />

                        <span class="badge badgeProjectCountryHeader"><img height="10em" src={inFlag} />&nbsp;&nbsp;|&nbsp;&nbsp;{projectsArray[4].name}</span>
                        <ProgressBar>
                            <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject4 / projectsArray[4].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject4 / projectsArray[4].originalVolume))}%`} />
                            <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject4 / projectsArray[4].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject4 / projectsArray[4].originalVolume)))}%`} />
                        </ProgressBar>
                        <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject4)} {t('kilos_reserved')} ({numberFormatterInteger.format(projectsArray[4].originalVolume)} {t('pre_booked')})</span>
                    </Col>


                    <br /><br />


                    {/*<Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6} className="formProjects2" style={{ "textAlign": "center" }}>
                            <Card>
                                <Carousel slide={true} variant="light">
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={vcs1950_picture} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={vcs1950_picture2} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                                <br />
                                <h6 class="card-title">Longyuan Mulilo North Wind Facility</h6>
                                <p class="card-text"><small>Powering wind to the South African grid. </small>
                                    <a href="https://info.starcb.com/view/880613720/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                        </svg>
                                    </a>
                                    <br />
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg3} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg4} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg6} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg13} />

                                </p>
                                <br />
                                <ProgressBar>
                                    <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject2 / projectsArray[2].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject2 / projectsArray[2].originalVolume))}%`} />
                                    <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject2 / projectsArray[2].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject2 / projectsArray[2].originalVolume)))}%`} />
                                </ProgressBar>
                                <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject2)} {t('tonnes_reserved')} ({numberFormatterInteger.format(projectsArray[2].originalVolume)} {t('pre_booked')})</span>

                                <div style={{
                                    width: '100%', height: '10%',
                                    position: 'absolute',
                                    top: '0%',
                                    left: '0%'
                                }}>
                                    <span class="badge badgeProjectCountry">South Africa <img height="10em" src={zaFlag} /></span>
                                    &nbsp;&nbsp;
                                    <span class="badge badgeProjectCountry">Renewables</span>
                                </div>
                            </Card>
                        </Col>

                        <br/><br/>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6} className="formProjects2" style={{ "textAlign": "center" }}>
                            <Card>
                                <Carousel slide={true} variant="light">
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={vcs674_picture5} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={vcs674_picture6} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                                <br />
                                <h6 class="card-title">Biodiversity Reserve Project</h6>
                                <p class="card-text"><small>Preserving Indonesia's peat swamp forests. </small>
                                    <a href="https://info.starcb.com/view/549931655/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                        </svg>
                                    </a>
                                    <br />
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg1} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg2} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg3} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg4} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg5} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg6} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg7} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg8} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg9} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg10} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg11} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg12} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg13} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg14} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg15} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg16} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg17} />&nbsp;


                                </p>
                                <ProgressBar>
                                    <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject1 / projectsArray[1].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject1 / projectsArray[1].originalVolume))}%`} />
                                    <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject1 / projectsArray[1].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject1 / projectsArray[1].originalVolume)))}%`} />
                                </ProgressBar>
                                <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject1)} {t('tonnes_reserved')} ({numberFormatterInteger.format(projectsArray[1].originalVolume)} {t('pre_booked')})</span>

                                <div style={{
                                    width: '100%', height: '10%',
                                    position: 'absolute',
                                    top: '0%',
                                    left: '0%'
                                }}>
                                    <span class="badge badgeProjectCountry">Indonesia <img height="10em" src={idFlag} /></span>
                                    &nbsp;&nbsp;
                                    <span class="badge badgeProjectCountry">Nature-based</span>
                                </div>
                            </Card>
                        </Col>

                        <br/><br/>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6} className="formProjects2" style={{ "textAlign": "center" }}>

                            <Card>
                                <Carousel slide={true} variant="light">
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={gs7599_picture1} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={gs7599_picture2} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                                <br />
                                <h6 class="card-title">Windkraftprojekt in Karnataka von OMWPL</h6>
                                <p class="card-text"><small>Bereitstellung von erneuerbarer Energie. </small>
                                    <a href="https://info.starcb.com/view/500728484/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                        </svg>
                                    </a>
                                    <br />
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg7} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg8} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg13} />

                                </p>

                                <ProgressBar>
                                    <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject4 / projectsArray[4].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject4 / projectsArray[4].originalVolume))}%`} />
                                    <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject4 / projectsArray[4].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject4 / projectsArray[4].originalVolume)))}%`} />
                                </ProgressBar>
                                <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject4)} {t('tonnes_reserved')} ({numberFormatterInteger.format(projectsArray[4].originalVolume)} {t('pre_booked')})</span>

                                <div style={{
                                    width: '100%', height: '10%',
                                    position: 'absolute',
                                    top: '0%',
                                    left: '0%'
                                }}>
                                    <span class="badge badgeProjectCountry">India <img height="10em" src={inFlag} /></span>
                                    &nbsp;&nbsp;
                                    <span class="badge badgeProjectCountry">Renewables</span>
                                </div>
                            </Card>
                        </Col>

                        <br/><br/>

                        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={6} className="formProjects2" style={{ "textAlign": "center" }}>
                            <Card>
                                <Carousel slide={true} variant="light">
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={gs7132_picture1} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <Card.Img variant="top" width="100%" src={gs7132_picture2} />
                                        <Carousel.Caption>
                                            <h3></h3>
                                            <h4></h4>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>
                                <br />
                                <h6 class="card-title">Mozambique Safe Water Project</h6>
                                <p class="card-text"><small>Providing safe water to households within Manica province. </small>
                                    <a href="https://info.starcb.com/view/125218946/" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill='var(--main-pdf-logo)' class="bi bi-file-pdf" viewBox="0 0 16 16">
                                            <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                            <path d="M4.603 12.087a.81.81 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.68 7.68 0 0 1 1.482-.645 19.701 19.701 0 0 0 1.062-2.227 7.269 7.269 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a10.954 10.954 0 0 0 .98 1.686 5.753 5.753 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.856.856 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.716 5.716 0 0 1-.911-.95 11.642 11.642 0 0 0-1.997.406 11.311 11.311 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.793.793 0 0 1-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361.01.022.02.036.026.044a.27.27 0 0 0 .035-.012c.137-.056.355-.235.635-.572a8.18 8.18 0 0 0 .45-.606zm1.64-1.33a12.647 12.647 0 0 1 1.01-.193 11.666 11.666 0 0 1-.51-.858 20.741 20.741 0 0 1-.5 1.05zm2.446.45c.15.162.296.3.435.41.24.19.407.253.498.256a.107.107 0 0 0 .07-.015.307.307 0 0 0 .094-.125.436.436 0 0 0 .059-.2.095.095 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a3.881 3.881 0 0 0-.612-.053zM8.078 5.8a6.7 6.7 0 0 0 .2-.828c.031-.188.043-.343.038-.465a.613.613 0 0 0-.032-.198.517.517 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.111.054.227.09.346z" />
                                        </svg>
                                    </a>
                                    <br />
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg3} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg5} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg6} />&nbsp;
                                    <img style={{ marginRight: "1px", marginTop: "5px" }} width='20em' src={sdg13} />

                                </p>
                                <ProgressBar>
                                    <ProgressBar striped variant="danger" now={numberFormatter.format(100 * (sumEmissionsProject3 / projectsArray[3].originalVolume))} key={3} label={`${numberFormatter.format(100 * (sumEmissionsProject3 / projectsArray[3].originalVolume))}%`} />
                                    <ProgressBar animated striped variant="success" now={numberFormatter.format(100 - (100 * (sumEmissionsProject3 / projectsArray[3].originalVolume)))} key={1} label={`${numberFormatter.format(100 - (100 * (sumEmissionsProject3 / projectsArray[3].originalVolume)))}%`} />
                                </ProgressBar>
                                <span class="badge badgeTonnesLight">{numberFormatter.format(sumEmissionsProject3)} {t('tonnes_reserved')} ({numberFormatterInteger.format(projectsArray[3].originalVolume)} {t('pre_booked')})</span>

                                <div style={{
                                    width: '100%', height: '10%',
                                    position: 'absolute',
                                    top: '0%',
                                    left: '0%'
                                }}>
                                    <span class="badge badgeProjectCountry">Mozambique <img height="10em" src={mzFlag} /></span>
                                    &nbsp;&nbsp;
                                    <span class="badge badgeProjectCountry">Community-based</span>
                                </div>
                            </Card>

                        </Col>

                    </Row>*/}





                </Col>

            </Row>





        </>
    )
}

export default CalculatorOffsettingToolIT