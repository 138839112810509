// https://react-bootstrap.netlify.app/components/navbar/
// https://react-bootstrap.github.io/components/buttons/
import React, { useState } from 'react';

import { Navbar, NavbarBrand, Form, NavItem, NavbarHeader, ToggleButtonGroup, ToggleButton, NavDropdown, Nav, Container, Row, Button, Col } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import mainLogo from './../img/SCB_Logo_White_RGB.png';



//import './../ScbApi.css';

//var cssStyle = ''


function NavbarApp(props) {



    //var cssStyle = props.myCssStyle

    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [cssStyle, setCssStyle] = useState("light")


    // Handling changes
    const handleChangeCssStyle = evt => {
        // Retrive the target name
        const name = evt.target.name;

        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;

        /*setCssStyle({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })*/
        setCssStyle(value)

        // Passing value to parent class
        //props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        props['cssStyle'](value);
        //props['pricePerTon'](pricePerTon);
        //props['carbonTons'](carbonTons);
        //props['flightPrice'](flightPrice);


        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);

    }










    return (
        <>
            {/*<Navbar collapseOnSelect expand="md" sticky="top" className="Navbar">
                <Container>
                    <Navbar.Brand className="me-auto" href="/renewables" style={{ color: "var(--navbar-color)" }}>
                        <img
                            src={mainLogo}
                            width="45"
                            className="d-inline-block align-top"
                            alt="SCB"
                        />{' '}
                        &nbsp;&nbsp;|&nbsp;&nbsp;<span className="Navbar-Title">SCB Carbon API</span>&nbsp;&nbsp;
                    </Navbar.Brand>
                </Container>
    </Navbar>*/}












            {/*<Navbar collapseOnSelect expand="md" sticky="top" className="Navbar">*/}
            <Navbar variant="dark" sticky="top" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#">
                        {/*<img
                            alt=""
                            src={mainLogo}
                            height="30"
                            className="d-inline-block align-center"
    />{' '}*/}
                        <img
                            src={mainLogo}
                            width="47"
                            className="d-inline-block align-top"
                            alt="API Demo"
                        /> API Demo{/*&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;demo&nbsp;&nbsp;&nbsp;&nbsp;*/}
                    </Navbar.Brand>

                    {/*<Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <NavDropdown title="Demo examples" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/">Airline</NavDropdown.Item>
                                <NavDropdown.Item href="/shipping">Shipping</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>*/}

                    <Nav>

                        <Nav.Link href="#dark">
                            <Form.Check
                                type="switch"
                                id="custom-switch-styling"
                                label={(String(cssStyle).valueOf() == 'dark') ? <i class="bi bi-moon"></i> : <i class="bi bi-sun"></i>}
                                name="cssStyle"
                                value={(String(cssStyle).valueOf() == 'dark') ? 'light' : 'dark'}
                                onChange={handleChangeCssStyle}
                                data-on="Hello<br>World"
                            />
                        </Nav.Link>
                    </Nav>

                    {/*<Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link exact href="/renewables"><Button variant="" className="custom-btn-renewables" size="sm"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cart-fill" viewBox="0 0 16 16">
                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                            </svg> Checkout form</Button></Nav.Link>
                            <Nav.Link exact href="/flightCalculator"><Button variant="" className="custom-btn-forestry" size="sm"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-airplane-fill" viewBox="0 0 16 16">
                                <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Z" />
                            </svg> Flight Calculator</Button></Nav.Link>

                            <NavDropdown title="Calculators" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-airplane-fill" viewBox="0 0 16 16">
                                    <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Z" />
                                </svg> Flight Calculator</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Raw Carbon</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">CP Individual Emitter</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.4"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-car-front-fill" viewBox="0 0 16 16">
                                    <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679c.033.161.049.325.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.807.807 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2H6ZM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17 1.247 0 3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
                                </svg>
                                    &nbsp;CP Car</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.4">Pet Plan</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.4">CP Employee Emitter</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>*/}


                </Container>
            </Navbar>






        </>
    );
}

export default NavbarApp;