// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************
import React, { useEffect, useState } from "react"

import scbLogoWhite from '../img/SCB_Logo_White_RGB.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';


import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


// ************************************************************
// ************************************************************
// Pop-ups
// ************************************************************
// ************************************************************
const renderTooltipCertificate = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Get a certificate per email (free).
    </Tooltip>
);

const renderTooltipOffsetting = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        <strong>Offset </strong> your <strong>flight</strong>!
    </Tooltip>
);

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Projects you will support</Popover.Header>
        <Popover.Body>
            Do the right thing by <strong>offsetting</strong> your <strong>flight</strong>.
        </Popover.Body>
    </Popover>
);


// Number formatting: thousand separator and 2 decimals
const numberFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
});


/**************************************************************/
/**************************************************************/
/*************** Simulate Network Request / timeout *********************************/
/**************************************************************/
/**************************************************************/
function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}





function ClientPortalIndex(props) {
    //const CalculatorForm = () => {

    // Retrieving values from parent
    var cabinClass = props.cabinClass
    var distanceInKm = props.distanceInKm
    var flightPrice = props.flightPrice
    //var carbonTons = props.carbonTonsPassedOn


    // JSON data retrieved from API
    const [calculation, setCalculation] = useState([])
    const [calculationCompact, setCalculationCompact] = useState([])
    const [calculationMidSize, setCalculationMidSize] = useState([])
    const [calculationFullSize, setCalculationFullSize] = useState([])

    const [carbonDataJson, setCarbonDataJson] = useState([])
    const [vending, setVending] = useState([])
    const [carbonTons, setCarbonTons] = useState(props.carbonTonsPassedOn)
    const [carbonTonsCompact, setCarbonTonsCompact] = useState(props.carbonTonsPassedOn)
    const [carbonTonsMidSize, setCarbonTonsMidSize] = useState(props.carbonTonsPassedOn * 1.3)
    const [carbonTonsFullSize, setCarbonTonsFullSize] = useState(props.carbonTonsPassedOn * 1.6)

    const [compensation, setCompensation] = useState([])
    const [pricePerTon, setPricePerTon] = useState(10.0)


    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [fullAddress, setFullAddress] = useState({
        // This variable can be accessed as follow: state.inputVcs
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        address: "",
        country: ""
    })

    // Handling changes
    const handleFullAddress = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;
        setFullAddress({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...fullAddress,
            [name]: value
        })
    }




    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [order, setOrder] = useState({
        // This variable can be accessed as follow: state.inputVcs
        fleetSizeCompact: props.fleetSizeCompactPassedOn,
        fleetSizeMidSize: props.fleetSizeMidSizePassedOn,
        fleetSizeFullSize: props.fleetSizeFullSizePassedOn,
    })


    // Handling changes
    const handleOrder = evt => {
        // Retrieve the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;

        setOrder({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...order,
            [name]: value
        })

        /*setOrder({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ['carbonTonsCalculated']: parseFloat(order.fleetSize) * parseFloat(carbonTons) * parseFloat(order.fleetType)
        })*/

        // Passing value to parent class
        props[name](value);

        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});

        //props['fleetSize'](order.fleetSize);
        //props['fleetType'](order.fleetType);
    }



    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [stateFleetType, setStateFleetType] = useState({
        // This variable can be accessed as follow: state.inputVcs
        compactOffered: true,
        midSizeOffered: false,
        fullSizeOffered: false
    })



    // Handling changes
    const handleChangeFleetTypeSelected = evt => {
        // Retrive the target name
        const name = evt.target.name;

        // If target.type is a checkbox, then retrieve the checked variable (true or false), otherwise the value
        //const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;

        // Checkbox feature
        var value = false
        if (evt.target.checked) {
            console.log("true returned")
            //setMessage('MidSize');
            value = true
        } else {
            console.log("false returned")
            //setMessage("No MidSize");
            value = false
        }


        setStateFleetType({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...stateFleetType,
            [name]: value   //value== true ? false : true
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        props['fleetSizeCompact'](order.fleetSizeCompact);
        props['fleetSizeMidSize'](order.fleetSizeMidSize);
        props['fleetSizeFullSize'](order.fleetSizeFullSize);


        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);
    }




    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [state, setStateOffsetting] = useState({
        // This variable can be accessed as follow: state.inputVcs
        offsetting: true,
    })

    // Handling changes
    const handleChangeOffsetting = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value =
            evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setStateOffsetting({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);
    }




    const [isSubmittedStatus, setSubmittedOrderStatus] = useState(-1) // -1 = initial state (no message to be displayed)

    /**************************************************************/
    /**************************************************************/
    /*************** Triggered when Submit button*********************************/
    /**************************************************************/
    /**************************************************************/
    // Loading button
    const [isLoading, setLoading] = useState(false);

    // Proceed each time isLoading = true
    useEffect(() => {
        console.log("useEffect called. ")
        //fetchDataCalculateCompact();
        //fetchDataCalculateMidSize()

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            if (stateFleetType.compactOffered) {
                console.log("my order.fleetSizeCompact just before VENDING method:")
                console.log(order.fleetSizeCompact)
            }
        }


    }, [isLoading]);

    //const handleClick = () => setLoading(true);




    const handleClick2 = () => {
        setLoading(true);

        simulateNetworkRequest().then(() => {

            if (fullAddress.email.includes('@') & fullAddress.company.length > 0) {
                setSubmittedOrderStatus(1)
                setFullAddress({ ['company']: '' })
                setFullAddress({ ['email']: '' })

                // If Compact vehicle select, then proceed
                if (stateFleetType.compactOffered) {
                    //fetchDataCalculateCompact()
                    //fetchDataVendCompact()
                }

            } else {
                setSubmittedOrderStatus(0)
            }
        });
    }


    return (
        <>
            <Row>
                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
                </Col>

                <Col xs={12} sm={12} md={12} lg={10} xl={10} xxl={10}>

                    <Form className="formTickers" style={{ "marginBottom": "3em", "padding": "0.1em", "position": "relative" }}>

                        <Table size="sm" style={{ borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Index name</th>
                                    <th style={{ width: "25%", textAlign: "center", "border": "0em" }}>Ticker</th>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Price</th>
                                    <th style={{ width: "10%", textAlign: "center", "border": "0em" }}>Premium</th>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Final price</th>
                                    <th style={{ width: "10%", textAlign: "center", "border": "0em" }}>Weight</th>
                                    <th style={{ width: "10%", textAlign: "left", "border": "0em" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Index name" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "paddingBottom": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                        </svg>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "paddingBottom": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                        </svg>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "paddingBottom": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                        </svg>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                    </td>

                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "paddingBottom": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                        </svg>&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#198754" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>

                                    </td>

                                </tr>

                                <Button variant="dark" size="sm" style={{ "position": "absolute", "top": "0px", "right": "0px" }}>
                                    <i class="bi bi-x-circle"></i>
                                </Button>
                                <span style={{ "position": "absolute", "top": "0px", "left": "0px" }}>
                                    <i class="bi bi-arrows-move"></i>
                                </span>

                                {/*<tr>
                                    <td colSpan={6} style={{ textAlign: "center" }}>
                                        <Button variant="success" size="sm">
                                            <i class="bi bi-plus-circle"></i> add ticker to index
                                        </Button>&nbsp;
                                    </td>
                                </tr>*/}
                            </tbody>
                        </Table>
                    </Form>

                    <Form className="formTickers" style={{ "marginBottom": "3em", "padding": "0.1em", "position": "relative" }}>

                        <Table size="sm" style={{ borderCollapse: "collapse" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Naming</th>
                                    <th style={{ width: "25%", textAlign: "center", "border": "0em" }}>Ticker</th>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Price</th>
                                    <th style={{ width: "10%", textAlign: "center", "border": "0em" }}>Premium</th>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Final price</th>
                                    <th style={{ width: "10%", textAlign: "center", "border": "0em" }}>Weight</th>
                                    <th style={{ width: "10%", textAlign: "left", "border": "0em" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Naming" value={order.fleetSizeMidSize} />
                                    </td>

                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "padding": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#198754" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>

                                    </td>
                                </tr>


                                <Button variant="dark" size="sm" style={{ "position": "absolute", "top": "0px", "right": "0px" }}>
                                    <i class="bi bi-x-circle"></i>
                                </Button>
                                <span style={{ "position": "absolute", "top": "0px", "left": "0px" }}>
                                    <i class="bi bi-arrows-move"></i>
                                </span>


                                {/*<tr>
                                    <td colSpan={6} style={{ "padding-top": "1.5em", textAlign: "center" }}>
                                        <Button variant="success" size="sm">
                                            <i class="bi bi-plus-circle"></i> add ticker to generate index
                                        </Button>&nbsp;
                                    </td>
                                </tr>*/}
                            </tbody>
                        </Table>
                    </Form>

                    <Form className="formTickers" style={{ "marginBottom": "3em", "padding": "0.1em", "position": "relative" }}>

                        <Table size="sm" style={{ borderCollapse: "collapse"}}>
                            <thead>
                                <tr>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Index name</th>
                                    <th style={{ width: "25%", textAlign: "center", "border": "0em" }}>Ticker</th>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Price</th>
                                    <th style={{ width: "10%", textAlign: "center", "border": "0em" }}>Premium</th>
                                    <th style={{ width: "15%", textAlign: "center", "border": "0em" }}>Final price</th>
                                    <th style={{ width: "10%", textAlign: "center", "border": "0em" }}>Weight</th>
                                    <th style={{ width: "10%", textAlign: "left", "border": "0em" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Index name" value={order.fleetSizeMidSize} />
                                    </td>

                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "padding": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                        </svg>

                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                    </td>

                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <Form.Control type="text" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} />
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <InputGroup.Text>$</InputGroup.Text>
                                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}><InputGroup size="sm">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                                    </InputGroup></td>
                                    <td style={{ "paddingBottom": "0em", "border": "0em" }}>
                                        <InputGroup size="sm">
                                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                                            <InputGroup.Text>%</InputGroup.Text>
                                        </InputGroup>
                                    </td>
                                    <td style={{ textAlign: "left", "padding": "0em", "border": "0em" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                        </svg>&nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#198754" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                                        </svg>

                                    </td>
                                </tr>


                                <Button variant="dark" size="sm" style={{ "position": "absolute", "top": "0px", "right": "0px" }}>
                                    <i class="bi bi-x-circle"></i>
                                </Button>
                                <span style={{ "position": "absolute", "top": "0px", "left": "0px" }}>
                                    <i class="bi bi-arrows-move"></i>
                                </span>


                                {/*<tr>
                                    <td colSpan={6} style={{ "padding-top": "1.5em", textAlign: "center" }}>
                                        <Button variant="success" size="sm">
                                            <i class="bi bi-plus-circle"></i> add ticker to generate index
                                        </Button>&nbsp;
                                    </td>
                                </tr>*/}
                            </tbody>
                        </Table>
                    </Form>



                    <Form className="formTickers" style={{ "marginBottom": "1em", "padding": "0.5em", textAlign: "center", "position": "relative" }}>
                        <Button variant="dark"><i class="bi bi-plus-circle"></i> Create new box</Button>
                    </Form>

                    {/*}
            <Form className="form mb-4">

                <Row>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        Single ticker
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "1.9em" }}>
                        <Form.Control type="number" size="sm" min="1" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Name to be displayed (ticker name if left empty)" value={order.fleetSizeMidSize} onChange={handleOrder} />
                    </Col>

                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                    </Col>
                </Row>

                <Row>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        <Form.Control type="number" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} onChange={handleOrder} />
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                    </Col>

                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "height": "1.9em" }}>
                    </Col>

                </Row>

                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "1.9em" }}>
                        <Button variant="dark" size="sm">
                            <i class="bi bi-plus-circle"></i> add ticker
                        </Button>&nbsp;
                        <Button variant="danger" size="sm">
                            <i class="bi bi-x-circle"></i> delete box
                        </Button>

                    </Col>
                </Row>

            </Form>


            <Form className="form mb-4">

                <Row>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        Index (composed of 3 tickers)
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "1.9em" }}>
                        <Form.Control type="number" size="sm" min="1" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="INDEX SEA WATER" value={order.fleetSizeMidSize} onChange={handleOrder} />
                    </Col>

                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch-midSize"
                            label="Define weights (avg. by default)"
                            name="midSizeOffered"
                            defaultChecked={stateFleetType.midSizeOffered ? "checked" : ""}
                            onChange={handleChangeFleetTypeSelected}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        <Form.Control type="number" size="sm" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} onChange={handleOrder} />
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "height": "1.9em" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                        </svg>
                    </Col>
                </Row>


                <Row>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        <Form.Control type="text" size="sm" min="1" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} onChange={handleOrder} />
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "height": "1.9em" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                        </svg>
                    </Col>

                </Row>


                <Row>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} style={{ "height": "1.9em" }}>
                        <Form.Control type="text" size="sm" min="1" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Ticker" value={order.fleetSizeMidSize} onChange={handleOrder} />
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Price" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Premium" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control aria-label="Amount (in USD)" type="number" min="0.0" step="0.1" placeholder="Final price" disabled="disabled" />
                        </InputGroup>
                    </Col>

                    <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} style={{ "height": "1.9em" }}>
                        <InputGroup size="sm">
                            <Form.Control aria-label="Amount (in %)" type="number" min="0.0" step="0.1" placeholder="Weight" />
                            <InputGroup.Text>%</InputGroup.Text>
                        </InputGroup>
                    </Col>

                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} style={{ "height": "1.9em" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#dc3545" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                        </svg>
                    </Col>

                </Row>



                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "1.9em" }}>
                        <Button variant="dark" size="sm">
                            <i class="bi bi-plus-circle"></i> add ticker
                        </Button>&nbsp;
                        <Button variant="danger" size="sm">
                            <i class="bi bi-x-circle"></i> delete box
                        </Button>
                    </Col>
                </Row>

            </Form>
            */}

                </Col>

                <Col xs={0} sm={0} md={0} lg={1} xl={1} xxl={1}>
                </Col>
            </Row>



            {isSubmittedStatus == 0 ?
                <Alert key='danger' variant='danger'>
                    Please provide your <b>client's company name</b> and <b>email</b> before compensating the order.
                </Alert>
                :
                null
            }

            {isSubmittedStatus == 1 ?
                <Alert key='success' variant='success'>
                    Thank you for your compensation.
                    <br /><br />You will receive your carbon neutral certificate per email in a few minutes.
                </Alert>
                :
                null
            }

        </>
    )
}

export default ClientPortalIndex