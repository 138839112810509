import React, { useEffect, useState } from "react"
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";


// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

// Tailor-made CSS
import './css/index.css';

//import './sticky-footer-navbar.css';


import NavbarApp from './components/NavbarApp';
import IndexShipping from './IndexShipping';
import IndexCarFleet from './IndexCarFleet';
import IndexFootball from './IndexFootball';
import IndexFootballBra from './IndexFootballBra';

import IndexFootballCP from './IndexFootballCP'; // with ClimatePositive certificate

import IndexAirline from './IndexAirline';
import IndexInternalOffsettingTool from "./IndexInternalOffsettingTool";
import IndexOffsettingToolFreight from "./IndexOffsettingToolFreight";
import IndexOffsettingToolIT from "./IndexOffsettingToolIT";


import IndexClientPortal from './IndexClientPortal';






const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element=""></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_shipping" element={<IndexShipping />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_car_fleet_order" element={<IndexCarFleet />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_freight" element={<IndexOffsettingToolFreight />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_it" element={<IndexOffsettingToolIT />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_journey_sporting_event_climate_positive" element={<IndexFootballCP />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_your_flight" element={<IndexAirline />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_journey_sporting_event" element={<IndexFootball />}></Route>
        <Route exact path="/Az21Ky98Ew73_offsetting_journey_sporting_event_bra" element={<IndexFootballBra />}></Route>
        <Route exact path="/Az21Ky98Ew73_internal_offsetting_tool" element={<IndexInternalOffsettingTool />}></Route>
        <Route exact path="/Az21Ky98Ew73_internal_client_portal" element={<IndexClientPortal />}></Route>
      </Routes>
    </BrowserRouter>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();