// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************

import React, { useEffect, useState } from "react"
import ListGroup from 'react-bootstrap/ListGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function YourCartOffsettingToolFreight(props) {

    var sumCo2 = parseFloat(props.sumCo2PassedOn)
    var pricePerTon = parseFloat(props.pricePerTon)


    // ************************************************************
    // ************************************************************
    // Number formatting: thousand separator and 2 decimals
    // ************************************************************
    // ************************************************************
    const numberFormatter = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
    });

    const numberFormatterZeroDigit = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
    });


    // ************************************************************
    // ************************************************************
    // Pop-ups
    // ************************************************************
    // ************************************************************
    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Based on your shipments in the left-hand side of the page.
        </Tooltip>
    );

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            By compensating your shipments, you support the emissions reduction projects.
        </Tooltip>
    );


    return (
        <>

            <div class="text-center">
                <h6>
                    <span class="">Offsetting summary</span>
                </h6>
            </div>

            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold text-offsetting">Total estimated emissions <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                        </OverlayTrigger></div>
                        Offsetting your shipments
                    </div>
                    <span class="text-offsetting">{numberFormatter.format(sumCo2.toFixed(3))} tons</span>
                </ListGroup.Item>

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold text-offsetting">Total CO2 compensation <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                        </OverlayTrigger></div>
                    </div>
                    <span class="text-offsetting">${numberFormatter.format((sumCo2 * pricePerTon).toFixed(2))}</span>
                </ListGroup.Item>
            </ListGroup>

        </>
    )
}

export default YourCartOffsettingToolFreight