// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************

import React, { useEffect, useState } from "react"
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';


import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function YourCartShipping(props) {
    // JSON data retrieved from API
    //const [calculation, setCalculation] = useState([])

    // Retrieving values from parent
    var distanceInKm = props.distanceInKm
    var cabinClass = props.cabinClass
    var offsetting = props.offsetting
    var compensation = props.compensation
    var pricePerTon = props.pricePerTon
    var carbonTons = props.carbonTons
    var flightPrice = props.flightPrice

    var total = 0;


    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Emissions are calculated based on the distance of your container shipments.
        </Tooltip>
    );

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            By compensating your shipment, you support the emissions reduction projects.
        </Tooltip>
    );

    const renderTooltipCapeTown = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Port of Cape Town
        </Tooltip>
    );

    const renderTooltipRotterdam = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Port of Rotterdam
        </Tooltip>
    );


    return (
        <>


            <div class="text-center">
                <h6>
                    <span class="">Your shipment</span>
                </h6>
            </div>


            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Shipping line <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCapeTown}><span class="badgeFlight">ZACPT</span></OverlayTrigger> <i class="bi bi-arrow-right"></i> <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipRotterdam}><span class="badgeFlight">NLRTM</span></OverlayTrigger></div>
                        Shipping #833
                    </div>
                    <span id="flight1">${flightPrice}</span>
                </ListGroup.Item>

                {!offsetting ? null :

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">Estimated emissions <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                        </div>
                        <span class="text-offsetting">{carbonTons} tons</span>
                    </ListGroup.Item>
                }

                {!offsetting ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">CO2 compensation <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                        </div>
                        <span class="text-offsetting">${compensation}</span>
                    </ListGroup.Item>
                }

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Total</div>
                    </div>
                    <strong><span name="total" value={flightPrice + parseFloat(compensation)}>${offsetting ? flightPrice + parseFloat(compensation) : flightPrice}</span></strong>
                </ListGroup.Item>


            </ListGroup>
            <br />

        </>
    )
}

export default YourCartShipping