// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************

import React, { useEffect, useState } from "react"
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


function YourCartInternalOffsettingTool(props) {



    // Retrieving values from parent
    var distanceInKm = props.distanceInKm
    var cabinClass = props.cabinClass
    var offsetting = props.offsetting
    var compensation = props.compensation
    var pricePerTon = props.pricePerTon
    var carbonTons = props.carbonTons
    var flightPrice = props.flightPrice

    var compactUnitPrice = props.compactUnitPrice
    var midSizeUnitPrice = props.midSizeUnitPrice
    var fullSizeUnitPrice = props.fullSizeUnitPrice


    var fleetType = props.fleetTypePassedOn
    var fleetSize = Math.round(props.fleetSizePassedOn)
    var carbonTonsUpdated = carbonTons


    var fleetSizeCompact = props.fleetSizeCompactPassedOn
    var fleetSizeMidSize = props.fleetSizeMidSizePassedOn
    var fleetSizeFullSize = props.fleetSizeFullSizePassedOn

    var compactOffered = props.compactOfferedPassedOn
    var midSizeOffered = props.midSizeOfferedPassedOn
    var fullSizeOffered = props.fullSizeOfferedPassedOn



    //var estimatedEmissions = 0


    const numberFormatter = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
    });

    const numberFormatterZeroDigit = Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
    });


    var fleetTypeName = ""

    /*if (fleetType == "1") {
        fleetTypeName = "Compact"
        flightPrice = flightPrice * 1
        totalFlightPrice = (fleetSizeCompact * flightPrice).toFixed(2)
        totalCompensation = (fleetSizeCompact * compensation)
        carbonTonsUpdated = carbonTons
        estimatedEmissions = (fleetSizeCompact * carbonTonsUpdated)

    }
    else if (fleetType == "1.3") {
        fleetTypeName = "Mid-Size"
        flightPrice = (flightPrice * 1.5)
        totalFlightPrice = (fleetSizeMidSize * flightPrice).toFixed(2)
        totalCompensation = (fleetSizeMidSize * compensation * 1.3)
        carbonTonsUpdated = (carbonTons * 1.3)
        estimatedEmissions = (fleetSizeMidSize * carbonTonsUpdated)
    }
    else {
        fleetTypeName = "Full-Size"
        flightPrice = (flightPrice * 3)
        totalFlightPrice = (fleetSizeFullSize * flightPrice).toFixed(2)
        totalCompensation = (fleetSizeFullSize * compensation * 1.6)
        carbonTonsUpdated = (carbonTons * 1.6)
        estimatedEmissions = (fleetSizeFullSize * carbonTonsUpdated)
    }*/



    var totalOrderCompact = 0
    var totalOrderMidSize = 0
    var totalOrderFullSize = 0

    var totalCompensationCompact = 0
    var totalCompensationMidSize = 0
    var totalCompensationFullSize = 0

    var carbonTonsCompact = 0
    var carbonTonsMidSize = 0
    var carbonTonsFullSize = 0

    var estimatedEmissionsCompact = 0
    var estimatedEmissionsMidSize = 0
    var estimatedEmissionsFullSize = 0


    if(compactOffered){
        totalOrderCompact = (fleetSizeCompact * compactUnitPrice).toFixed(2)
        totalCompensationCompact = parseFloat((fleetSizeCompact * compensation))
        carbonTonsCompact = parseFloat(carbonTons)
        estimatedEmissionsCompact = parseFloat(fleetSizeCompact) * parseFloat(carbonTonsCompact)
    }
    else{
        totalOrderCompact = 0
        totalCompensationCompact = 0
        carbonTonsCompact = 0
        estimatedEmissionsCompact = 0
    }

    if(midSizeOffered){
        totalOrderMidSize = (fleetSizeMidSize * midSizeUnitPrice).toFixed(2)
        totalCompensationMidSize = parseFloat((fleetSizeMidSize * compensation * 1.3))
        carbonTonsMidSize = parseFloat((carbonTons * 1.3))
        estimatedEmissionsMidSize = parseFloat(fleetSizeMidSize) * parseFloat(carbonTonsMidSize)
    }
    else{
        totalOrderMidSize = 0
        totalCompensationMidSize = 0
        carbonTonsMidSize = 0
        estimatedEmissionsMidSize = 0
    }

    if(fullSizeOffered){
        totalOrderFullSize = (fleetSizeFullSize * fullSizeUnitPrice).toFixed(2)
        totalCompensationFullSize = parseFloat((fleetSizeFullSize * compensation * 1.6))
        carbonTonsFullSize = parseFloat((carbonTons * 1.6))
        estimatedEmissionsFullSize = parseFloat(fleetSizeFullSize) *parseFloat(carbonTonsFullSize)
    }
    else{
        totalOrderFullSize = 0
        totalCompensationFullSize = 0
        carbonTonsFullSize = 0
        estimatedEmissionsFullSize = 0
    }


    var totalOrder = 0
    var totalCompensation = 0
    var totalEstimatedEmissions = 0    

    totalOrder = parseFloat(totalOrderCompact) + parseFloat(totalOrderMidSize) + parseFloat(totalOrderFullSize)
    totalCompensation = parseFloat(totalCompensationCompact) + parseFloat(totalCompensationMidSize) + parseFloat(totalCompensationFullSize)
    totalEstimatedEmissions = parseFloat(estimatedEmissionsCompact) + parseFloat(estimatedEmissionsMidSize) + parseFloat(estimatedEmissionsFullSize)



    const renderTooltipCo2Emission = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Emissions are calculated based your fleet size and type of vehicles.
        </Tooltip>
    );

    const renderTooltipCo2Compensation = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            By compensating your fleet order, you support the emissions reduction projects.
        </Tooltip>
    );

    const renderTooltipCapeTown = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Tooltip text 1
        </Tooltip>
    );

    const renderTooltipRotterdam = (
        <Tooltip id="button-tooltip" style={{ textAlign: 'left' }}>
            Tooltip text 2
        </Tooltip>
    );



    return (
        <>

            <div class="text-center">
                <h6>
                    <span class="">Your client's cart</span>
                </h6>
            </div>

            <ListGroup as="ol">
                {!compactOffered ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div class="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">{fleetSizeCompact} Compact vehicles</div>
                            ${numberFormatterZeroDigit.format(compactUnitPrice)} per vehicle
                            <br />{carbonTonsCompact.toFixed(2)} tons of CO2 per vehicle per year
                        </div>

                        <span>${numberFormatterZeroDigit.format(totalOrderCompact)}</span>
                    </ListGroup.Item>
                }


                {!midSizeOffered ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div class="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">{fleetSizeMidSize} Mid-Size vehicles</div>
                            ${numberFormatterZeroDigit.format(midSizeUnitPrice)} per vehicle
                            <br />{carbonTonsMidSize.toFixed(2)} tons of CO2 per vehicle per year
                        </div>

                        <span>${numberFormatterZeroDigit.format(totalOrderMidSize)}</span>
                    </ListGroup.Item>
                }

                {!fullSizeOffered ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div class="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">{fleetSizeFullSize} Full-Size vehicles</div>
                            ${numberFormatterZeroDigit.format(fullSizeUnitPrice)} per vehicle
                            <br />{carbonTonsFullSize.toFixed(2)} tons of CO2 per vehicle per year
                        </div>

                        <span>${numberFormatterZeroDigit.format(totalOrderFullSize)}</span>
                    </ListGroup.Item>
                }



                {!offsetting ? null :

                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">Total estimated emissions <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Emission}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                            Offsetting your yearly emissions
                        </div>
                        <span class="text-offsetting">{numberFormatter.format(totalEstimatedEmissions)} tons</span>
                    </ListGroup.Item>
                }

                {!offsetting ? null :
                    <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start"
                    >
                        <div className="ms-2 me-auto">
                            <div className="fw-bold text-offsetting">Total CO2 compensation <OverlayTrigger trigger="hover" class="font" placement="right" overlay={renderTooltipCo2Compensation}><i class="bi bi-info-circle-fill" style={{ "color": 'var(--main-form-background)' }}></i>
                            </OverlayTrigger></div>
                        </div>
                        <span class="text-offsetting">${numberFormatter.format(totalCompensation)}</span>
                    </ListGroup.Item>
                }

                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Total</div>
                    </div>
                    <strong><span name="total" value={numberFormatter.format(parseFloat(totalOrder) + parseFloat(totalCompensation))}>${offsetting ? numberFormatter.format(parseFloat(totalOrder) + parseFloat(totalCompensation)) : numberFormatter.format(parseFloat(totalOrder))}</span></strong>
                </ListGroup.Item>


            </ListGroup>
            <br />

        </>
    )
}

export default YourCartInternalOffsettingTool