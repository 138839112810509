import React, { useEffect, useState } from "react"

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Offcanvas from 'react-bootstrap/Offcanvas';

import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';

import ReactDOM from 'react-dom/client';
//import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';

import NavbarApp from './components/NavbarApp';
//import Header from './components/Header';
//import Footer from './components/Footer';

//import Component from './components/Component';

//import SearchUser from './components/SearchUser';
import YourCartOffsettingToolFreight from './components/YourCartOffsettingToolFreight';
import VcmProjectCarousel1 from './components/VcmProjectCarousel1';
import CalculatorFormOffsettingToolFreight from './components/CalculatorFormOffsettingToolFreight';


import ReactPlayer from 'react-player'


//import './ScbApi.css';


//import './sticky-footer-navbar.css';


// source: https://coverr.co/
import myVideo from './videos/video (720p).mp4'


// *******************************************************
// *******************************************************
// Banner images
// *******************************************************
// *******************************************************
//import banner1 from './img/aircraft7.jpg';
import banner2 from './img/airplane-wing-in-the-sky2.jpg';
//import banner3 from './img/airplane-wing-in-the-sky3.jpg';
//import banner4 from './img/passports3_slate3.jpeg';
import banner5 from './img/sky4.jpg';
//import banner6 from './img/sky4.jpg';
//import banner7 from './img/sky5.jpg';
//import banner8 from './img/aircraft8.jpg';
import banner9 from './img/sea1d.jpg';
import banner9b from './img/containers1c.jpg';
import banner9c from './img/sea_and_ship2.jpg';
//import banner10 from './img/aircraft_mercury.jpg';
//import banner11 from './img/Flying_child2.jpg';
//import banner13 from './img/child-on-suitcase2.jpg';
//import banner14 from './img/bay-travel-illu-web2.jpg';
//import banner15 from './img/blog-article-flying-with-kids3.jpg';
//import banner16 from './img/Sunrises_and_sunsets_Airplane_Little_girls_4.jpg';
//import banner17 from './img/Airplane_Little_girls_3.jpg';

//import './ScbApi.css';


import {
    BrowserRouter,
    Routes, // instead of "Switch"
    Route,
} from "react-router-dom";



const arrayBannerWallpaper = [
    //banner1,
    //banner2,
    //banner3,
    //banner4,
    //banner5,
    //banner6,
    //banner7,
    //banner8,
    //banner9,
    //banner10,
    //banner11,
    //banner13,
    //banner14,
    //banner15,
    banner9,
    banner9b,
    banner9c
    //banner16
    //banner17
];

const arrayBannerWallpaperLength = arrayBannerWallpaper.length;
var bannerSelected = Math.floor(Math.random() * arrayBannerWallpaperLength);


function IndexOffsettingToolFreight() {

    let [cssStyle, setCssStyle] = useState('light');
    // Default toggle "Carbon Compensate sate" = true
    let [offsetting, setOffsetting] = useState(true);
    let [pricePerTon, setPricePerTon] = useState(15);




    let [seaTankersArray, setSeaTankersArray] = useState();
    // Callback function to receive data from the child
    const receivedDataFromSeaTankersArray = (data) => {
        setSeaTankersArray(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
        console.log('childData updated SEA TANKER:', seaTankersArray);
    }, [seaTankersArray]);



    let [cargoShipsArray, setCargoShipsArray] = useState();
    // Callback function to receive data from the child
    const receivedDataFromCargoShipsArray = (data) => {
        setCargoShipsArray(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
        console.log('childData updated CARGO SHIPS:', cargoShipsArray);
    }, [cargoShipsArray]);



    let [sumCo2, setSumCo2] = useState();
    // Callback function to receive data from the child
    const receivedDataFromSumCo2 = (data) => {
        setSumCo2(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
    }, sumCo2);






    const [showHowDoesItWork, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);




    // *********************************************************************************************************************
    // *********************************************************************************************************************
    // ***** Interacting with CSS Custom Properties in JavaScript
    // *********************************************************************************************************************
    // *********************************************************************************************************************
    // https://spacejelly.dev/posts/how-to-create-css-custom-properties-that-dynamically-update-with-react-javascript/

    if (String(cssStyle).valueOf() != "dark") {
        document.documentElement.style.setProperty('--main-font', 'Roboto Condensed, Quicksand'); // Comfortaa
        document.documentElement.style.setProperty('--main-navbar-background', 'var(--scbNightSky)');

        document.documentElement.style.setProperty('--main-font-color-light', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-font-color', 'var(--scbWhite)');
        document.documentElement.style.setProperty('--main-background-color', 'var(--scbWhite)');
        document.documentElement.style.setProperty('--main-form-background', '#01579B'); // #FFD600

        document.documentElement.style.setProperty('--scb-project-background', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--scb-project-font-color', 'var(--scbCloud)');

        document.documentElement.style.setProperty('--main-border-colour', '#000');
        document.documentElement.style.setProperty('--border-style', 'solid');
        document.documentElement.style.setProperty('--border-width', '0px');
        document.documentElement.style.setProperty('--border-width-badge', '0px');
        document.documentElement.style.setProperty('--border-width-banner-bottom', '1px');

        document.documentElement.style.setProperty('--main-pdf-logo', 'var(--negative)');

    } else {
        document.documentElement.style.setProperty('--main-font', 'Roboto Condensed, Quicksand');
        document.documentElement.style.setProperty('--main-navbar-background', 'var(--scbNightSky)');

        document.documentElement.style.setProperty('--main-font-color-light', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-font-color', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--main-background-color', 'var(--scbWhite)');
        document.documentElement.style.setProperty('--main-form-background', '#FDD835');

        document.documentElement.style.setProperty('--scb-project-background', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--scb-project-font-color', 'var(--scbCloud)');

        document.documentElement.style.setProperty('--main-border-colour', '#000');
        document.documentElement.style.setProperty('--border-style', 'solid');
        document.documentElement.style.setProperty('--border-width', '2px');
        document.documentElement.style.setProperty('--border-width-badge', '0px');
        document.documentElement.style.setProperty('--border-width-banner-bottom', '1px');

        document.documentElement.style.setProperty('--main-pdf-logo', 'var(--negative)');
    }

    //root.render(
    return (
        <>
            <NavbarApp
                cssStyle={setCssStyle}
            />

            <main class="">
                <div class="video-container" style={{ height: '7rem' }}>
                    <ReactPlayer class="video" url={myVideo} playing={true} loop={true} muted={true} width='100%' height='auto' />
                    <span style={{ position: 'relative', top: '25%', left: '9%' }} class="badgeCompany">&nbsp;Offsetting solution for sea freight&nbsp;&nbsp;<a href="#" class="link-light"><i onClick={handleShow} class="bi bi-question-circle-fill"></i></a></span>
                </div>

                <br />


                <Offcanvas show={showHowDoesItWork} placement="end" onHide={handleClose}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>How does it work</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div class="howto">
                            <Card>
                                <Card.Body>
                                    {/*<Card.Title>How does it work</Card.Title>*/}
                                    <Card.Text>
                                        <ul>
                                            <li>This Carbon Offsetting App allows you to leverage the <a class="carbonCompensateDocUrl" href="https://carboncompensate.climatepositive.com/developer-docs" target="_blank">CarbonCompensate API</a> to calculate and offset freight emissions.<br /><br /></li>
                                            <li>It is meant to be used by a back office to make bulk offset purchases.<br /><br /></li>
                                            <li>Click on the <Button variant="dark" size="sm" href="#">Add tanker shipment&nbsp;<i class="bi bi-droplet"></i></Button> or <Button variant="dark" size="sm" href="#">Add cargo shipment&nbsp;<i class="bi bi-box-seam"></i></Button> buttons to list all your shipments.<br /><br /></li>
                                            <li>When are you done, click "compensate".<br /><br /></li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>

                        <br />
                        <div class="row" style={{ "textAlign": "center" }}>
                            <footer class="footer">
                                <div class="container">
                                    <span class="">© 2023 &#183; SCB Environmental Markets</span>
                                </div>
                            </footer>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>




                <Row>

                    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}>
                    </Col>


                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={8}>
                        {/*<div class="card howto">
                            <Card>
                                <Card.Body>
                                    <Card.Title>How does it work</Card.Title>
                                    <Card.Text>
                                        <ul>
                                            <li>This Carbon Offsetting App allows you to leverage the <a href="https://carboncompensate.climatepositive.com/developer-docs" target="_blank">CarbonCompensate API</a> to calculate and offset freight emissions.</li>
                                            <li>It is meant to be used by a back office to make bulk offset purchases.</li>
                                            <li>Click on the "Add <i class="bi bi-droplet"></i>" or "Add <i class="bi bi-box-seam"></i>" buttons below to list all your shipments (i.e. type of tanker/ship, load, distance, etc.)</li>
                                            <li>To complete the action, click "compensate".</li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>*/}

                        <CalculatorFormOffsettingToolFreight
                            pricePerTon={pricePerTon}
                            sendSeaTankersArrayToParent={receivedDataFromSeaTankersArray}
                            sendCargoShipsArrayToParent={receivedDataFromCargoShipsArray}
                            sendSumCo2={receivedDataFromSumCo2}
                        />


                    </Col>


                    <Col xs={0} sm={0} md={0} lg={0} xl={3} xxl={3}>


                        <Row>

                            <Col xs={0} sm={0} md={0} lg={0} xl={2} xxl={2}>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
                                <YourCartOffsettingToolFreight
                                    pricePerTon={pricePerTon}
                                    sumCo2PassedOn={sumCo2}
                                />
                                <br /> <br />
                                <VcmProjectCarousel1 />
                            </Col>

                            <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}>
                            </Col>

                        </Row>


                    </Col>




                </Row>





            </main>

            <div class="row" style={{ "textAlign": "center" }}>
                <footer class="footer">
                    <div class="container">
                        <span class="">© 2023 &#183; SCB Environmental Markets</span>
                    </div>
                </footer>
            </div>


        </>
    );

}


export default IndexOffsettingToolFreight;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
