// https://react-bootstrap.netlify.app/components/navbar/
// https://react-bootstrap.github.io/components/buttons/
import React, { useState } from 'react';

import { Navbar, NavbarBrand, Form, NavItem, NavbarHeader, ToggleButtonGroup, ToggleButton, NavDropdown, Nav, Container, Row, Button, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Offcanvas from 'react-bootstrap/Offcanvas';

import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import mainLogo from './../img/SCB_Logo_White_RGB.png';

//import Flags from 'react-flags-select';
import ReactCountryFlag from "react-country-flag";

import VcmProjectCarouselIT from './VcmProjectCarouselIT';

// For multilingual capabilities
import { useTranslation } from 'react-i18next';
import i18n from './i18n'; // Import your i18n configuration
import { I18nextProvider } from 'react-i18next';



function MyVerticallyCenteredModal(props) {

    const { t, i18n } = useTranslation();

    return (
        <Modal
            id="myModal"
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('how_does_it_work')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*<h4>How does it work</h4>*/}
                {/*<Card.Title>How does it work</Card.Title>*/}

                <ul>
                    <li>{t('how_does_it_work_desc_1')}<br /><br /></li>
                    {/*<li>{t('how_does_it_work_desc_2')}<br /><br /></li>*/}
                    <li>{t('how_does_it_work_desc_3')}<br /><br /></li>
                    <li>{t('how_does_it_work_desc_4')}<br /></li>
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}




function NavbarAppOffsettingToolIT(props) {

    const { t, i18n } = useTranslation();

    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [cssStyle, setCssStyle] = useState("light")

    const [modalShow, setModalShow] = React.useState(false);
    const [showHowDoesItWork, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    // Handling changes
    const handleChangeCssStyle = evt => {
        // Retrive the target name
        const name = evt.target.name;

        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;

        /*setCssStyle({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })*/
        setCssStyle(value)

        // Passing value to parent class
        //props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        props['cssStyle'](value);
        //props['pricePerTon'](pricePerTon);
        //props['carbonTons'](carbonTons);
        //props['flightPrice'](flightPrice);


        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);

    }


    return (
        <>
            {/*<Navbar collapseOnSelect expand="md" sticky="top" className="Navbar">
                <Container>
                    <Navbar.Brand className="me-auto" href="/renewables" style={{ color: "var(--navbar-color)" }}>
                        <img
                            src={mainLogo}
                            width="45"
                            className="d-inline-block align-top"
                            alt="SCB"
                        />{' '}
                        &nbsp;&nbsp;|&nbsp;&nbsp;<span className="Navbar-Title">SCB Carbon API</span>&nbsp;&nbsp;
                    </Navbar.Brand>
                </Container>
    </Navbar>*/}



            {/*<Navbar collapseOnSelect expand="md" sticky="top" className="Navbar">*/}
            <Navbar variant="dark" sticky="top" expand="lg">
                <Container fluid>
                    <Navbar.Brand href="#">
                        <img
                            src={mainLogo}
                            width="47"
                            className="d-inline-block align-top"
                            alt="API Demo"
                        /> {t('app_title')}
                    </Navbar.Brand>

                    {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#projects" onClick={handleShow}>{t('projects_we_support')}</Nav.Link>
                            <Nav.Link href="#how" onClick={() => setModalShow(true)}>
                                {t('how_does_it_work')}
                            </Nav.Link>
                            {/*<NavDropdown title="Dropdown" id="basic-nav-dropdown">
                                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">
                                    Another action
                                </NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Separated link
                                </NavDropdown.Item>
</NavDropdown>*/}
                        </Nav>

                        <Nav>
                            <ReactCountryFlag
                                className="hand-cursor"
                                onClick={() => i18n.changeLanguage('de')}
                                countryCode="DE"
                                svg
                                style={{
                                    width: '2em',
                                    height: '1.3em'
                                }}
                                title="Deutsch"
                            />
                            &nbsp;&nbsp;
                            <ReactCountryFlag
                                className="hand-cursor"
                                onClick={() => i18n.changeLanguage('en')}
                                countryCode="GB"
                                svg
                                style={{
                                    width: '2em',
                                    height: '1.3em'
                                }}
                                title="English"
                            />
                        </Nav>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <Nav>
                            <Nav.Link href="#dark">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-styling"
                                    label={(String(cssStyle).valueOf() == 'dark') ? <i class="bi bi-sun-fill custom-colour-pannel-icon"></i> : <i class="bi bi-sun-fill custom-colour-pannel-icon"></i>}
                                    name="cssStyle"
                                    value={(String(cssStyle).valueOf() == 'dark') ? 'light' : 'dark'}
                                    onChange={handleChangeCssStyle}
                                    data-on=""
                                    title="Change colours"
                                />
                            </Nav.Link>
                        </Nav>


                    </Navbar.Collapse>
                </Container>
            </Navbar>



            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />


            <Offcanvas show={showHowDoesItWork} placement="end" onHide={handleClose}>
                {/*<Offcanvas.Header closeButton>
                    <Offcanvas.Title>Emissions reduction projects you support</Offcanvas.Title>
                </Offcanvas.Header>*/}
                <Offcanvas.Body>
                    <div class="howto">
                        <VcmProjectCarouselIT />
                    </div>

                    <br />
                    <div class="row" style={{ "textAlign": "center" }}>
                        <footer class="footer">
                            <div class="container">
                                <span class="">© 2023 &#183; SCB Environmental Markets</span>
                            </div>
                        </footer>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
}

export default NavbarAppOffsettingToolIT;