import React, { useEffect, useState } from "react"

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Badge from 'react-bootstrap/Badge';
import Nav from 'react-bootstrap/Nav';

import ReactDOM from 'react-dom/client';
//import App from './App';
//import reportWebVitals from './reportWebVitals';

import {
    BrowserRouter,
    Routes, // instead of "Switch"
    Route,
} from "react-router-dom";

import NavbarAppOffsettingToolIT from './components/NavbarAppOffsettingToolIT';

import YourCartOffsettingToolFreight from './components/YourCartOffsettingToolFreight';
import VcmProjectCarousel1 from './components/VcmProjectCarousel1';
import CalculatorFormOffsettingToolIT from './components/CalculatorFormOffsettingToolIT';

// For multilingual capabilities
import { useTranslation } from 'react-i18next';
import i18n from './components/i18n'; // Import your i18n configuration
import { I18nextProvider } from 'react-i18next';


import ReactPlayer from 'react-player'
import myVideo from './videos/coverr-coniferous-forest-9381-1080p.mp4'


// *******************************************************
// *******************************************************
// Banner images
// *******************************************************
// *******************************************************
import banner2 from './img/airplane-wing-in-the-sky2.jpg';
import banner5 from './img/sky4.jpg';
import banner9 from './img/containers1a.jpg';
import banner9b from './img/containers1b.jpg';

const arrayBannerWallpaper = [
    banner9,
    banner9b,
];

const arrayBannerWallpaperLength = arrayBannerWallpaper.length;
var bannerSelected = Math.floor(Math.random() * arrayBannerWallpaperLength);


// ************************************************************
// ************************************************************
// Number formatting: thousand separator and 2 decimals
// ************************************************************
// ************************************************************
const numberFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
});



function IndexOffsettingToolIT() {

    // For multilingual features
    const { t, i18n } = useTranslation();

    let [cssStyle, setCssStyle] = useState('light');

    // Default toggle "Carbon Compensate sate" = true
    let [offsetting, setOffsetting] = useState(true);
    let [pricePerTon, setPricePerTon] = useState(15);


    let [productionPhaseArray, setProductionPhaseArray] = useState();
    // Callback function to receive data from the child
    const receivedDataFromProductionPhaseArray = (data) => {
        setProductionPhaseArray(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
        console.log('childData updated SEA TANKER:', productionPhaseArray);
    }, [productionPhaseArray]);



    let [transportationPhaseArray, setTransportationPhaseArray] = useState();
    // Callback function to receive data from the child
    const receivedDataFromTransportationPhaseArray = (data) => {
        setTransportationPhaseArray(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
        console.log('childData updated CARGO SHIPS:', transportationPhaseArray);
    }, [transportationPhaseArray]);


    let [usePhaseArray, setUsePhaseArray] = useState();
    // Callback function to receive data from the child
    const receivedDataFromUsePhaseArray = (data) => {
        setUsePhaseArray(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
        console.log('childData updated CARGO SHIPS:', usePhaseArray);
    }, [usePhaseArray]);



    let [sumCo2, setSumCo2] = useState();
    // Callback function to receive data from the child
    const receivedDataFromSumCo2 = (data) => {
        setSumCo2(data);
    };

    // Use useEffect to react to changes in childData
    useEffect(() => {
        // Here, you can perform actions when childData changes
    }, sumCo2);




    // *********************************************************************************************************************
    // *********************************************************************************************************************
    // ***** Interacting with CSS Custom Properties in JavaScript
    // *********************************************************************************************************************
    // *********************************************************************************************************************
    // https://spacejelly.dev/posts/how-to-create-css-custom-properties-that-dynamically-update-with-react-javascript/

    if (String(cssStyle).valueOf() != "dark") {
        document.documentElement.style.setProperty('--main-font', 'Roboto Condensed, Quicksand'); // Comfortaa
        document.documentElement.style.setProperty('--main-navbar-background', 'var(--scbNightSky)');

        document.documentElement.style.setProperty('--main-font-color-light', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-font-color', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--main-background-color', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-form-background', 'var(--scbStone)'); // #81C784

        document.documentElement.style.setProperty('--scb-project-background', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--scb-project-font-color', 'var(--scbCloud)');

        document.documentElement.style.setProperty('--main-border-colour', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--border-style', 'solid');
        document.documentElement.style.setProperty('--border-width', '4px');
        document.documentElement.style.setProperty('--border-width-badge', '0px');
        document.documentElement.style.setProperty('--border-width-banner-bottom', '4px');

        document.documentElement.style.setProperty('--main-pdf-logo', 'var(--negative)');

    } else {
        document.documentElement.style.setProperty('--main-font', 'Roboto Condensed, Quicksand');
        document.documentElement.style.setProperty('--main-navbar-background', 'var(--scbNightSky)');

        document.documentElement.style.setProperty('--main-font-color-light', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-font-color', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--main-background-color', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-form-background', '#C8E6C9');

        document.documentElement.style.setProperty('--scb-project-background', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--scb-project-font-color', 'var(--scbCloud)');

        document.documentElement.style.setProperty('--main-border-colour', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--border-style', 'solid');
        document.documentElement.style.setProperty('--border-width', '4px');
        document.documentElement.style.setProperty('--border-width-badge', '0px');
        document.documentElement.style.setProperty('--border-width-banner-bottom', '4px');

        document.documentElement.style.setProperty('--main-pdf-logo', 'var(--negative)');
    }

    //root.render(
    return (
        <>
            <NavbarAppOffsettingToolIT
                cssStyle={setCssStyle}
            />

            <main class="background-div">

                <div class="video-container" style={{ height: '4rem' }}>
                    <ReactPlayer class="video" url={myVideo} playing={true} loop={true} muted={true} width='100%' height='auto' />
                    {/*<span style={{ position: 'relative', top: '25%', left: '9%' }} class="badgeCompany">{t('banner_offsetting')} {numberFormatter.format(sumCo2)} {t('banner_tons_of_co2')}{numberFormatter.format(pricePerTon * sumCo2)}</span>*/}
                </div>

                {/*<div class="bannerTop" style={{backgroundImage: `url(${arrayBannerWallpaper[bannerSelected]})`, backgroundRepeat: 'repeat', height: '9em', width: '100%' }}>
                    <span style={{ position: 'relative', top: '25%', left: '9%' }} class="badgeCompany">{t('banner_offsetting')} {numberFormatter.format(sumCo2)} {t('banner_tons_of_co2')}{numberFormatter.format(pricePerTon * sumCo2)}</span>
            </div>*/}


                <Row>

                    <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>

                        <CalculatorFormOffsettingToolIT
                            pricePerTon={pricePerTon}
                            sendProductionPhaseArrayToParent={receivedDataFromProductionPhaseArray}
                            sendTransporationPhaseArrayToParent={receivedDataFromTransportationPhaseArray}
                            sendUsePhaseArrayToParent={receivedDataFromUsePhaseArray}

                            sendSumCo2={receivedDataFromSumCo2}
                        />
                    </Col>

                    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}>
                        <Row>
                            <Col xs={0} sm={0} md={0} lg={0} xl={2} xxl={2}>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={10}>
                                {/*<YourCartOffsettingToolFreight
                                    pricePerTon={pricePerTon}
                                    sumCo2PassedOn={sumCo2}
                    />*/}
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={0} xl={0} xxl={0}>
                            </Col>
                        </Row>
                    </Col>

                </Row>

            </main>

            <div class="row" style={{ "textAlign": "center" }}>
                <footer class="footer">
                    <div class="container">
                        {/*<span class="">© 2023 &#183; SCB Environmental Markets</span>*/}
                    </div>
                </footer>
            </div>

        </>
    );

}


export default IndexOffsettingToolIT;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
