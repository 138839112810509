import React, { useEffect, useState } from "react"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Badge from 'react-bootstrap/Badge';

import ReactDOM from 'react-dom/client';
//import './index.css';
//import App from './App';
//import reportWebVitals from './reportWebVitals';

import NavbarApp from './components/NavbarApp';
//import Header from './components/Header';
//import Footer from './components/Footer';

//import Component from './components/Component';

//import SearchUser from './components/SearchUser';
import YourCartFootball from './components/YourCartFootball';
import CalculatorFormFootball from './components/CalculatorFormFootball';
import VcmProjectCarousel1 from './components/VcmProjectCarousel1';

import ReactPlayer from 'react-player'


//import './ScbApi.css';


//import './sticky-footer-navbar.css';


// source: https://coverr.co/
//import myVideo from './videos/coverr-airplane-flying-through-the-clouds-1025-1080p.mp4'
//import myVideo from './videos/coverr-evergreen-hill-2693-1080p.mp4'
//import myVideo from './videos/coverr-lake-in-raglan-new-zealand-7639-1080p.mp4'
//import myVideo from './videos/coverr-leaf-8718-1080p.mp4'
//import myVideo from './videos/coverr-mountains-in-tierra-del-fuego-argentina-8442-1080p.mp4'
//import myVideo from './videos/coverr-trees-with-green-leaves-in-the-park-1669-1080p.mp4'
//import myVideo from './videos/coverr-waves-crashing-together-6456-1080p.mp4'
//import myVideo from './videos/coverr-the-perfect-wave-2027-1080p.mp4'
//import myVideo from './videos/coverr-greenery-by-the-lake-8523-1080p.mp4'
//import myVideo from './videos/coverr-man-sitting-near-a-waterfall-5858-1080p.mp4'
//import myVideo from './videos/coverr-tree-trunk-3626-1080p.mp4'
//import myVideo from './videos/coverr-waterfall-in-a-rainforest-8763-1080p.mp4'
//import myVideo from './videos/coverr-woman-running-in-the-jungle-5102-1080p.mp4'



import myVideo from './videos/coverr-soccer-field-4469-1080p.mp4'



// *******************************************************
// *******************************************************
// Banner images
// *******************************************************
// *******************************************************
//import banner1 from './img/aircraft7.jpg';
import banner2 from './img/airplane-wing-in-the-sky2.jpg';
//import banner3 from './img/airplane-wing-in-the-sky3.jpg';
//import banner4 from './img/passports3_slate3.jpeg';
import banner5 from './img/sky4.jpg';
//import banner6 from './img/sky4.jpg';
//import banner7 from './img/sky5.jpg';
//import banner8 from './img/aircraft8.jpg';
import banner9 from './img/sea1d.jpg';
import banner9b from './img/containers1c.jpg';
import banner9c from './img/sea_and_ship2.jpg';
//import banner10 from './img/aircraft_mercury.jpg';
//import banner11 from './img/Flying_child2.jpg';
//import banner13 from './img/child-on-suitcase2.jpg';
//import banner14 from './img/bay-travel-illu-web2.jpg';
//import banner15 from './img/blog-article-flying-with-kids3.jpg';
//import banner16 from './img/Sunrises_and_sunsets_Airplane_Little_girls_4.jpg';
//import banner17 from './img/Airplane_Little_girls_3.jpg';

//import './ScbApi.css';


import {
    BrowserRouter,
    Routes, // instead of "Switch"
    Route,
} from "react-router-dom";



const arrayBannerWallpaper = [
    //banner1,
    //banner2,
    //banner3,
    //banner4,
    //banner5,
    //banner6,
    //banner7,
    //banner8,
    //banner9,
    //banner10,
    //banner11,
    //banner13,
    //banner14,
    //banner15,
    banner9,
    banner9b,
    banner9c
    //banner16
    //banner17
];

const arrayBannerWallpaperLength = arrayBannerWallpaper.length;
var bannerSelected = Math.floor(Math.random() * arrayBannerWallpaperLength);


function IndexFootball() {


    let [cssStyle, setCssStyle] = useState('light');

    // Default toggle "Carbon Compensate sate" = true
    let [offsetting, setOffsetting] = useState(true);
    //console.log("indexCheckout.js: " + offsetting)

    let [compensation, setCompensation] = useState(1.50);
    let [carbonTons, setCarbonTons] = useState(0.1500);

    let [cabinClass, setCabinClass] = useState('Economy');
    let [distanceInKm, setDistanceInKm] = useState(400);
    let [flightPrice, setFlightPrice] = useState(120);
    let [pricePerTon, setPricePerTon] = useState(10);

    //let [calculation, setCalculation] = useState(false);

    //const root = ReactDOM.createRoot(document.getElementById('root'));

    // *********************************************************************************************************************
    // *********************************************************************************************************************
    // ***** Interacting with CSS Custom Properties in JavaScript
    // *********************************************************************************************************************
    // *********************************************************************************************************************
    // https://spacejelly.dev/posts/how-to-create-css-custom-properties-that-dynamically-update-with-react-javascript/
    //console.log(getComputedStyle(document.documentElement).getPropertyValue('--scbOcean'))

    //console.log("cssStyle on IndexCheckout.js: ")
    //console.log(cssStyle)
    if (String(cssStyle).valueOf() != "dark") {
        document.documentElement.style.setProperty('--main-font', 'Roboto Condensed, Quicksand'); // Comfortaa
        document.documentElement.style.setProperty('--main-navbar-background', 'var(--scbNightSky)');

        document.documentElement.style.setProperty('--main-font-color-light', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-font-color', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--main-background-color', '#F2F2F2');
        document.documentElement.style.setProperty('--main-form-background', '#81C784'); // #FFD600

        document.documentElement.style.setProperty('--scb-project-background', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--scb-project-font-color', 'var(--scbCloud)');

        document.documentElement.style.setProperty('--main-border-colour', '#81C784');
        document.documentElement.style.setProperty('--border-style', 'solid');
        document.documentElement.style.setProperty('--border-width', '1px');
        document.documentElement.style.setProperty('--border-width-badge', '0px');
        document.documentElement.style.setProperty('--border-width-banner-bottom', '1px');

        document.documentElement.style.setProperty('--main-pdf-logo', 'var(--negative)');

    } else {
        document.documentElement.style.setProperty('--main-font', 'Roboto Condensed, Quicksand');
        document.documentElement.style.setProperty('--main-navbar-background', 'var(--scbNightSky)');

        document.documentElement.style.setProperty('--main-font-color-light', 'var(--scbCloud)');
        document.documentElement.style.setProperty('--main-font-color', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--main-background-color', 'var(--scbWhite)');
        document.documentElement.style.setProperty('--main-form-background', '#F48FB1');

        document.documentElement.style.setProperty('--scb-project-background', 'var(--scbNightSky)');
        document.documentElement.style.setProperty('--scb-project-font-color', 'var(--scbCloud)');

        document.documentElement.style.setProperty('--main-border-colour', '#F48FB1');
        document.documentElement.style.setProperty('--border-style', 'solid');
        document.documentElement.style.setProperty('--border-width', '0px');
        document.documentElement.style.setProperty('--border-width-badge', '0px');
        document.documentElement.style.setProperty('--border-width-banner-bottom', '1px');

        document.documentElement.style.setProperty('--main-pdf-logo', 'var(--negative)');


    }




    //root.render(
    return (
        <>
            <NavbarApp
                cssStyle={setCssStyle}
            />


            <main class="">

                <div class="video-container" style={{ height: '7rem' }}>
                    <ReactPlayer class="video" url={myVideo} playing={true} loop={true} muted={true} width='100%' height='auto' />
                    <span style={{ position: 'relative', top: '25%', left: '9%' }} class="badgeCompany">Foot Inc.</span>
                </div>


                {/*<div class="bannerTop" style={{ backgroundImage: `url(${arrayBannerWallpaper[bannerSelected]})`, backgroundRepeat: 'repeat', height: '7em', width: '100%' }}>
                    <span style={{ position: 'relative', top: '25%', left: '9%' }} class="badgeCompany">Shipping Inc.</span>
    </div>*/}


                {/*<div class="container">*/}

                <br />

                {/*<div class="col-12 col-sm-12 col-md-12">
                            &nbsp;
</div>*/}

                <Row>
                    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                        <div class="text-center">
                            <h2>Checkout</h2>
                            {/*<p class="lead">Below is an example form built entirely with Bootstrap’s form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p>*/}
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                    </Col>

                    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}>
                    </Col>
                </Row>



                <Row>
                    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}>

                    </Col>
                    {/*<div class="col-0 col-sm-0 col-md-0 col-lg-0 col-xl-1">
                        &nbsp;
</div>*/}


                    <Col xs={12} sm={12} md={6} lg={6} xl={4} xxl={4}>
                        <CalculatorFormFootball
                            cabinClass={cabinClass}
                            distanceInKm={distanceInKm}
                            offsetting={setOffsetting}
                            compensation={setCompensation}
                            pricePerTon={setPricePerTon}
                            carbonTons={setCarbonTons}
                            carbonTonsPassedOn={carbonTons}
                            flightPrice={setFlightPrice}
                            //calculation={calculation}
                        />
                    </Col>

                    <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                        <YourCartFootball
                            cabinClass={cabinClass}
                            distanceInKm={distanceInKm}
                            offsetting={offsetting}
                            compensation={compensation}
                            pricePerTon={pricePerTon}
                            carbonTons={carbonTons}
                            flightPrice={flightPrice}
                            //calculation={setCalculation}
                        />
                    </Col>

                    <Col xs={12} sm={12} md={12} lg={12} xl={3} xxl={3}>
                        <VcmProjectCarousel1 />
                    </Col>


                    <Col xs={0} sm={0} md={0} lg={0} xl={1} xxl={1}>

                    </Col>


                </Row>


            </main>



            {/*<div class="col-12 col-sm-12 col-md-12">
                            &nbsp;
</div>*/}



            {/*<App />*/}







            {/*<div class="row">
                <footer class="footer">
                    <div class="container">
                        <span class="">© 2023 SCB Environmental Markets.</span>
                    </div>
                </footer>
</div>*/}


        </>
    );

}


export default IndexFootball;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
