// https://dev.to/collegewap/fetch-and-display-data-from-api-in-react-js-53bp

// ************************************************************
// ************************************************************
// Fetching data in React based on user input (onChange)
// ************************************************************
// ************************************************************
import React, { useEffect, useState } from "react"

import scbLogoWhite from '../img/SCB_Logo_White_RGB.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


// ************************************************************
// ************************************************************
// Pop-ups
// ************************************************************
// ************************************************************
const renderTooltipCertificate = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Get a certificate per email (free).
    </Tooltip>
);

const renderTooltipOffsetting = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        <strong>Offset </strong> your <strong>flight</strong>!
    </Tooltip>
);

const popover = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Projects you will support</Popover.Header>
        <Popover.Body>
            Do the right thing by <strong>offsetting</strong> your <strong>flight</strong>.
        </Popover.Body>
    </Popover>
);


// Number formatting: thousand separator and 2 decimals
const numberFormatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
});


/**************************************************************/
/**************************************************************/
/*************** Simulate Network Request / timeout *********************************/
/**************************************************************/
/**************************************************************/
function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 1000));
}





function CalculatorInternalOffsettingTool(props) {
    //const CalculatorForm = () => {

    // Retrieving values from parent
    var cabinClass = props.cabinClass
    var distanceInKm = props.distanceInKm
    var flightPrice = props.flightPrice
    //var carbonTons = props.carbonTonsPassedOn


    // JSON data retrieved from API
    var [calculation, setCalculation] = useState([])
    const [calculationCompact, setCalculationCompact] = useState([])
    const [calculationMidSize, setCalculationMidSize] = useState([])
    const [calculationFullSize, setCalculationFullSize] = useState([])

    const [carbonDataJson, setCarbonDataJson] = useState([])
    const [vending, setVending] = useState([])
    const [carbonTons, setCarbonTons] = useState(props.carbonTonsPassedOn)
    const [carbonTonsCompact, setCarbonTonsCompact] = useState(props.carbonTonsPassedOn)
    const [carbonTonsMidSize, setCarbonTonsMidSize] = useState(props.carbonTonsPassedOn * 1.3)
    const [carbonTonsFullSize, setCarbonTonsFullSize] = useState(props.carbonTonsPassedOn * 1.6)

    const [compensation, setCompensation] = useState([])
    const [pricePerTon, setPricePerTon] = useState(10.0)


    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [fullAddress, setFullAddress] = useState({
        // This variable can be accessed as follow: state.inputVcs
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        address: "",
        country: ""
    })

    // Handling changes
    const handleFullAddress = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;
        setFullAddress({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...fullAddress,
            [name]: value
        })
    }







    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [order, setOrder] = useState({
        // This variable can be accessed as follow: state.inputVcs
        fleetSizeCompact: props.fleetSizeCompactPassedOn,
        fleetSizeMidSize: props.fleetSizeMidSizePassedOn,
        fleetSizeFullSize: props.fleetSizeFullSizePassedOn,
    })



    const [optionsApiCalculateCompact, setOptionsApiCalculateCompact] = useState(
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
                'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
                'Access-Control-Allow-Origin': '*',
                'mode': 'no-cors'
            },
            body: JSON.stringify({
                'calculator_id': 'SCBRawCarbon_DT34',
                'item_description': 'Internal/bulk offsetting of by the Sales Team',
                'carbon_tons': '' + carbonTonsCompact + '',
                'quantity': order.fleetSizeCompact
            })
        }
    )    



    // Handling changes
    const handleOrder = evt => {
        // Retrieve the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value = evt.target.value;

        setOrder({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...order,
            [name]: value
        })

        /*setOrder({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ['carbonTonsCalculated']: parseFloat(order.fleetSize) * parseFloat(carbonTons) * parseFloat(order.fleetType)
        })*/

        // Passing value to parent class
        props[name](value);

        props['fleetSizeCompact'](order.fleetSizeCompact);
        props['fleetSizeMidSize'](order.fleetSizeMidSize);
        props['fleetSizeFullSize'](order.fleetSizeFullSize);


        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});

        //props['fleetSize'](order.fleetSize);
        //props['fleetType'](order.fleetType);



        setOptionsApiCalculateCompact(
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
                    'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
                    'Access-Control-Allow-Origin': '*',
                    'mode': 'no-cors'
                },
                body: JSON.stringify({
                    'calculator_id': 'SCBRawCarbon_DT34',
                    'item_description': 'Internal/bulk offsetting of by the Sales Team',
                    'carbon_tons': '' + carbonTonsCompact + '',
                    'quantity': order.fleetSizeCompact
                })
            }
        )


    }



    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [stateFleetType, setStateFleetType] = useState({
        // This variable can be accessed as follow: state.inputVcs
        compactOffered: true,
        midSizeOffered: false,
        fullSizeOffered: false
    })



    // Handling changes
    const handleChangeFleetTypeSelected = evt => {
        // Retrive the target name
        const name = evt.target.name;

        // If target.type is a checkbox, then retrieve the checked variable (true or false), otherwise the value
        //const value = evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;

        // Checkbox feature
        var value = false
        if (evt.target.checked) {
            console.log("true returned")
            //setMessage('MidSize');
            value = true
        } else {
            console.log("false returned")
            //setMessage("No MidSize");
            value = false
        }


        setStateFleetType({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...stateFleetType,
            [name]: value   //value== true ? false : true
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        props['fleetSizeCompact'](order.fleetSizeCompact);
        props['fleetSizeMidSize'](order.fleetSizeMidSize);
        props['fleetSizeFullSize'](order.fleetSizeFullSize);


        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);
    }




    /**************************************************************/
    /**************************************************************/
    /*************** API CALCULATE COMPACT*********************************/
    /**************************************************************/
    /**************************************************************/
    /*var optionsApiCalculateCompact = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
            'Host': 'https://carboncompensate.starcb.com/api/v1/calculate',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            'calculator_id': 'SCBRawCarbon_DT34',
            'item_description': 'Internal/bulk offsetting of by the Sales Team',
            'carbon_tons': '' + carbonTonsCompact + '', // ''/quotes are important here.
            'quantity': String(order.fleetSizeCompact) // 
            //'quantity': parseInt(order.fleetSizeCompact) // ''/quotes are important here.
        })
    }*/







    const fetchDataCalculateCompact = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/calculate`, optionsApiCalculateCompact)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                //setCarbonDataJson(result.data)
                setCalculation(result.data.calculation)
                //setCompensation(result.data.calculation.total_cost)
                //setPricePerTon(result.data.calculation.price_per_ton)
            })
        /*.then(data => {
            //setUsers(data)
            console.log(data)
        })*/
    }

    console.log("optionsApiCalculateCompact: ")
    console.log(optionsApiCalculateCompact)

    console.log('calculation:')
    console.log(calculation)





    /**************************************************************/
    /**************************************************************/
    /*************** API VEND COMPACT*********************************/
    /**************************************************************/
    /**************************************************************/
    var optionsApiVendCompact = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'CP75D535880C514461BC603F779C9BF4C6:TABcHqVDhorbxE9HsujdeibqmvGJVBLC0BjG3bSWKA9XUq0Ctlvb2R5ixzpXXSXU',
            'Host': 'https://carboncompensate.starcb.com/api/v1/vend',
            'Access-Control-Allow-Origin': '*',
            'mode': 'no-cors'
        },
        body: JSON.stringify({
            calculation,
            'customer_reference': '',
            'consumer_email': fullAddress.email,
            'on_behalf_of_name': fullAddress.company
        })
    }

    const fetchDataVendCompact = () => {
        fetch(`https://carboncompensate.starcb.com/api/v1/vend`, optionsApiVendCompact)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(response.data.calculation)
                setVending(result.data)

            })
        /*.then(data => {
            //setUsers(data)
            console.log(data)
        })*/
    }






    // Setting original values (it should pick the data from the DB or the prior day)
    // At the moment, it is picked up from a JSON file
    const [state, setStateOffsetting] = useState({
        // This variable can be accessed as follow: state.inputVcs
        offsetting: true,
    })

    // Handling changes
    const handleChangeOffsetting = evt => {
        // Retrive the target name
        const name = evt.target.name;
        // If target.type is a checkbox, then retrieve the checked variable, otherwise the value
        const value =
            evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        setStateOffsetting({
            // The spread syntax allows us to get all of the properties of the object and to update only the desired parts.
            ...state,
            [name]: value
        })

        // Passing value to parent class
        props[name](value);
        // You can also pass a json as follow:
        //props["jsonResponse"]({"name": name, "value": value});
        //props["jsonResponse"]([{"name": "compensation", "value": compensation}, {"name": name, "value": value}]);
    }




    const [isSubmittedStatus, setSubmittedOrderStatus] = useState(-1) // -1 = initial state (no message to be displayed)

    /**************************************************************/
    /**************************************************************/
    /*************** Triggered when Submit button*********************************/
    /**************************************************************/
    /**************************************************************/
    // Loading button
    const [isLoading, setLoading] = useState(false);

    // Proceed each time isLoading = true
    useEffect(() => {
        console.log("useEffect called. ")
        fetchDataCalculateCompact()
        //fetchDataCalculateMidSize()

        if (isLoading) {
            simulateNetworkRequest().then(() => {
                setLoading(false);
            });

            if (stateFleetType.compactOffered) {
                console.log("my order.fleetSizeCompact just before VENDING method:")
                console.log(order.fleetSizeCompact)
                fetchDataVendCompact()
            }
        }


    }, [isLoading]);

    //const handleClick = () => setLoading(true);




    const handleClick2 = () => {
        setLoading(true);

        simulateNetworkRequest().then(() => {

            if (fullAddress.email.includes('@') & fullAddress.company.length > 0) {
                setSubmittedOrderStatus(1)
                setFullAddress({ ['company']: '' })
                setFullAddress({ ['email']: '' })

                // If Compact vehicle select, then proceed
                /*if (stateFleetType.compactOffered) {
                    fetchDataCalculateCompact()
                    fetchDataVendCompact()
                }*/

            } else {
                setSubmittedOrderStatus(0)
            }
        });
    }


    return (
        <>
            <div className="text-center">
                <h6>
                    <span className="">Your client's order</span>
                </h6>
            </div>

            <Form className="form mb-4">
                <fieldset>

                    {/*<div class="row">
                        <div class="col-md-2">
                            &nbsp;
                        </div>

                        <div class="col-md-5">
                            <label for="carTypes" class="form-label">Car types</label><br />
                            <input type="radio" id="compact" name="fleetType" value="1.1" checked={order.fleetType === "1.1"} onChange={handleOrder} />
                            <label for="html">&nbsp;Compact</label><br />
                            <input type="radio" id="midSize" name="fleetType" value="1.3" checked={order.fleetType === "1.3"} onChange={handleOrder} />
                            <label for="css">&nbsp;Mid-Size</label><br />
                            <input type="radio" id="fullSize" name="fleetType" value="1.6" checked={order.fleetType === "1.6"} onChange={handleOrder} />
                            <label for="javascript">&nbsp;Full-Size</label>
                        </div>

                        <div class="col-sm-3">
                            <label for="firstName" class="form-label">Fleet size</label><br />
                            <input type="number" class="form-control" id="fleetSize" placeholder="" required="" name="fleetSize" value={order.fleetSize} onChange={handleOrder} />
                            <div class="invalid-feedback">
                                Valid fleet size is required.
                            </div>
                        </div>

                        <div class="col-md-2">
                            &nbsp;
                        </div>
                    </div>*/}




                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            Vehicle type 22
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            Fleet size
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch-compact"
                                label="Compact"
                                name="compactOffered"
                                defaultChecked={stateFleetType.compactOffered ? "checked" : ""}
                                //value={stateFleetType.compactOffered}
                                onChange={handleChangeFleetTypeSelected}
                            />
                        </Col>

                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            {stateFleetType.compactOffered ?
                                <Form.Control type="number" min="1" id="fleetSizeCompact" name="fleetSizeCompact" placeholder="Normal text" value={order.fleetSizeCompact} onChange={handleOrder} disabled={stateFleetType.compactOffered == true ? "" : "on"} />
                                : null}
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch-midSize"
                                label="Mid-Size"
                                name="midSizeOffered"
                                defaultChecked={stateFleetType.midSizeOffered ? "checked" : ""}
                                onChange={handleChangeFleetTypeSelected}
                            />
                        </Col>

                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            {stateFleetType.midSizeOffered ?
                                <Form.Control type="number" min="1" id="fleetSizeMidSize" name="fleetSizeMidSize" placeholder="Normal text" value={order.fleetSizeMidSize} onChange={handleOrder} disabled={stateFleetType.midSizeOffered == true ? "" : "on"} />
                                : null}
                        </Col>

                    </Row>



                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch-fullSize"
                                label="Full-Size"
                                name="fullSizeOffered"
                                defaultChecked={stateFleetType.fullSizeOffered ? "checked" : ""}
                                onChange={handleChangeFleetTypeSelected}
                            />
                        </Col>

                        <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ "height": "2.5em" }}>
                            {stateFleetType.fullSizeOffered ?
                                <Form.Control type="number" min="1" id="fleetSizeFullSize" name="fleetSizeFullSize" placeholder="Normal text" value={order.fleetSizeFullSize} onChange={handleOrder} disabled={stateFleetType.fullSizeOffered == true ? "" : "on"} />
                                : null}
                        </Col>

                    </Row>



                </fieldset>
            </Form>

            <br />



            <div className="text-center">
                <h6>
                    <span className="">Compensation on behalf of your client</span>
                </h6>
            </div>

            <Form className="form mb-4">
                <fieldset>

                    {/*<div class="col-sm-6">
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledTextInput">Distance (in kms)</Form.Label>
                            <Form.Control id="disabledTextInput" placeholder="Number of kms" />
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="disabledSelect">Class</Form.Label>
                        <Form.Select id="disabledSelect">
                            <option>Disabled select</option>
                            <option>Economy</option>
                            <option>Business</option>

                        </Form.Select>
    </Form.Group>*/}

                    <div class="row">

                        <div class="col-12">
                            <label for="company" class="form-label">Company name {/*<span class="text-muted">(Optional)</span>*/}</label>
                            <input type="text" class="form-control" id="company" placeholder="" required="" name="company" value={fullAddress.company} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Please enter a valid company name.
                            </div>
                        </div>

                        <div class="col-12">
                            <label for="email" class="form-label">Email {/*<span class="text-muted">(Optional)</span>*/}</label>
                            <input type="email" class="form-control" id="email" placeholder="" required="" name="email" value={fullAddress.email} onChange={handleFullAddress} />
                            <div class="invalid-feedback">
                                Please enter a valid email address.
                            </div>
                            <br />
                        </div>

                        {/*<div class="col-8">
                            <label for="address" class="form-label">Address</label>
                            <input type="text" class="form-control" id="address" placeholder="&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022; &#x2022;&#x2022;" required="" name="address" value={fullAddress.address} onChange={handleFullAddress} disabled="disabled" />
                            <div class="invalid-feedback">
                                Please enter your address.
                            </div>
                        </div>

                        <div class="col-md-4">
                            <label for="country" class="form-label">Country</label>
                            <select class="form-select" id="country" required="" name="country" value={fullAddress.country} onChange={handleFullAddress} disabled="disabled">
                                <option value="other" selected>&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;&#x2022;</option>
                                <option>Choose...</option>
                                <option>Australia</option>
                                <option>Canada</option>
                                <option>Switzerland</option>
                                <option>United Kingdom</option>
                                <option>USA</option>
                            </select>
                            <div class="invalid-feedback">
                                Please select a valid country.
                            </div>
                            <br />
                        </div>*/}


                        {/*<div class="col-md-6">
                            <label for="distance" class="form-label">Distance (in kms)</label>
                            <input type="number" class="form-control" id="distance" placeholder="" required="" />
                            <div class="invalid-feedback">
                                Number of kms.
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for="class" class="form-label">Class</label>
                            <select class="form-select" id="class" required="">
                                <option value="">Choose...</option>
                                <option>Economy</option>
                                <option>Business</option>
                            </select>
                            <div class="invalid-feedback">
                                Please select a class.
                            </div>
</div>*/}


                        {/*<div class="col-md-4">
                            <label for="zip" class="form-label">Zip</label>
                            <input type="text" class="form-control" id="zip" placeholder="" required="" />
                            <div class="invalid-feedback">
                                Zip code required.
                            </div>
</div>*/}


                        {/*<hr class="my-2" />*/}


                        <div class="row">

                            <div class="col-lg-6">

                                {/*<OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipOffsetting}
                                >
                                    <ToggleButton
                                        className="mb-2"
                                        id="toggle-check1"
                                        type="checkbox"
                                        variant="outline-primary"
                                        checked={checkedOffsetting}
                                        value="1"
                                        onChange={(e) => checkedOffsetting(e.currentTarget.checkedOffsetting)}
                                    >
                                        Compensate flight Emissions&nbsp;&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-airplane-fill" viewBox="0 0 16 16">
                                            <path d="M6.428 1.151C6.708.591 7.213 0 8 0s1.292.592 1.572 1.151C9.861 1.73 10 2.431 10 3v3.691l5.17 2.585a1.5 1.5 0 0 1 .83 1.342V12a.5.5 0 0 1-.582.493l-5.507-.918-.375 2.253 1.318 1.318A.5.5 0 0 1 10.5 16h-5a.5.5 0 0 1-.354-.854l1.319-1.318-.376-2.253-5.507.918A.5.5 0 0 1 0 12v-1.382a1.5 1.5 0 0 1 .83-1.342L6 6.691V3c0-.568.14-1.271.428-1.849Z" />
                                        </svg>

                                    </ToggleButton>
                                </OverlayTrigger>*/}

                                <Form.Check
                                    type="switch"
                                    id="custom-switch-offsetting"
                                    label="Carbon compensate your client's order"
                                    name="offsetting"
                                    defaultChecked={state.offsetting ? "checked" : ""}
                                    value={state.offsetting}
                                    onChange={handleChangeOffsetting}
                                />

                            </div>
                            <div class="col-lg-6">
                                {/*<OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltipCertificate}
                                >
                                    <ToggleButton
                                        className="mb-2"
                                        id="toggle-check2"
                                        type="checkbox"
                                        variant="outline-secondary"
                                        checked={checkedCertificate}
                                        value="1"
                                        onChange={(e) => setCheckedCertificate(e.currentTarget.checkedCertificate)}
                                    >
                                        Certificate&nbsp;&nbsp;<i class="bi bi-file-pdf-fill"></i>

                                    </ToggleButton>
                                </OverlayTrigger>*/}


                                <Form.Check
                                    type="switch"
                                    id="custom-switch-certificate"
                                    label="Email carbon neutral certificate"
                                    defaultChecked="checked"
                                    disabled="disabled"
                                />

                                <br />
                            </div>

                        </div>



                        <div className="text-center">
                            {/*<h4>
                                    <Badge bg="dark" text="light" as="Button" class="w-100 btn btn-primary btn-lg" type="submit">Continue to checkout</Badge>
                            </h4>*/}
                            {/*<Button class="btnSubmit"
                                disabled={isLoading}
                                onClick={!isLoading ? handleClick : null}
                            >
                                {isLoading ? 'Processing payment…' : checkoutButtonText}
                        </Button>*/}
                            <input class="btnSubmit" type="button" onClick={!isLoading ? handleClick2 : null} value={isLoading ? '  Processing…  ' : '  Compensate your client\'s order  '} disabled={isSubmittedStatus == 1 ? 'disabled' : ''} />
                        </div>

                    </div>

                </fieldset>
            </Form>


            {isSubmittedStatus == 0 ?
                <Alert key='danger' variant='danger'>
                    Please provide your <b>client's company name</b> and <b>email</b> before compensating the order.
                </Alert>
                :
                null
            }

            {isSubmittedStatus == 1 ?
                <Alert key='success' variant='success'>
                    Thank you for your compensation.
                    <br /><br />You will receive your carbon neutral certificate per email in a few minutes.
                </Alert>
                :
                null
            }

        </>
    )
}

export default CalculatorInternalOffsettingTool